// import papa from "papaparse";
import countries from "../data/countries.json";
import legendItems from "../entities/LegendItems";

class LoadCountriesTask {
    constructor(filtering, aggr_result) { //sector
        this.results = filtering  
        this.aggr_results = aggr_result
        // this.s = sector 
    }    
    
    setState = null;

    mapCountries = countries.features;
    resultCountries = this.results

    load = (setState) => {
        this.setState = setState;
        this.#processResultsData(this.results, this.aggr_results)//, this.s)
        // papa.parse(this.covid19DataUrl, {
        //     download: true,
        //     header: true,
        //     complete: (result) => this.#processCovidData(result.data)
        // })
    }

    // updateMapCountries = (mapCountries) => {
    //     this.setState = mapCountries
    // }

    #processResultsData = (results, aggr_results) => { //sector
        if (this.results !== undefined) {
            for (let i=0; i < this.mapCountries.length; i++) {
                const mapCountry = this.mapCountries[i];
                // top 5 inputs
                const resultCountry = results.filter((resultCountry)=> resultCountry.ISO_A2 === mapCountry.properties.ISO_A2).sort((a,b) => b.result - a.result).slice(0,5)
                const aggrResultCountry = aggr_results.filter((aggr_results)=> aggr_results.ISO_A2 === mapCountry.properties.ISO_A2)
                //const resultCountry = results.filter((resultCountry)=> resultCountry.ISO_A2 === mapCountry.properties.ISO_A2 && resultCountry.sector == sector)
                // mapCountry.properties.result = 0.0;
                // mapCountry.properties.resultText = "No data"  
               if(resultCountry !== null || resultCountry !== []) {
                    mapCountry.properties.list = resultCountry     
                    mapCountry.properties.aggrResults = aggrResultCountry               
                }
                this.#setCountryColor(mapCountry);
            }  
            this.setState(this.mapCountries) 
        }
    };

    #setCountryColor = (mapCountry) => {        
        if(mapCountry.properties.aggrResults[0] !== undefined && mapCountry.properties.aggrResults[0] !== []) {
            const legendItem = legendItems.find((legendItem) => legendItem.isFor(mapCountry.properties.aggrResults[0].aggr_impact))                        
            if (legendItem != null || legendItem != undefined) {
                mapCountry.properties.color = legendItem.color
            }
        } else {
            mapCountry.properties.color = "white"
            mapCountry.resultText = "No data"
        }
    }

    // #formatNumberWithCommas = (number) => {
    //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // }
}

export default LoadCountriesTask;