import LegendItem from "./LegendItem";

const legendItems = [
    new LegendItem(
        "> 50 %",
        "#486D40",
        (result) => result >= 0.50
    ),
    new LegendItem(
        "30 - 50 %",
        "#4E7C49",
        (result) => result >= 0.30 && result < 0.50,
    ),
    new LegendItem(
        "10 - 30 %",
        "#548C52",
        (result) => result >= 0.1 && result < 0.30,
    ),
    new LegendItem(
        "5 - 10 %",
        "#5B9B5E",
        (result) => result >= 0.05 && result < 0.1,
    ),
    new LegendItem(
        "4 - 5 %",
        "#64AA6B",
        (result) => result >= 0.04 && result < 0.05,
    ),
    new LegendItem(
        "3 - 4 %",
        "#6DB97A",
        (result) => result >= 0.03 && result < 0.04,
    ),
    new LegendItem(
        "2 - 3 %",
        "#84C488",
        (result) => result >= 0.02 && result < 0.03,
    ),
    new LegendItem(
        "1 - 2 %",
        "#9DCF9B",
        (result) => result >= 0.01 && result < 0.02,
    ),
    new LegendItem(
        "0 - 1 %",
        "#B8DAB2",
        (result) => result >= 0 && result < 0.01,
    ),
    new LegendItem("No Data", "#ffffff", (result) => true)
];

export default legendItems;