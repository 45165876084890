import React from "react";
import { MapContainer, GeoJSON, LayersControl, Marker, Popup, ZoomControl, FeatureGroup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from 'leaflet';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
// import Papa from "papaparse";
// import regional from "../data/Regional.csv"

const customIcon = new Icon({
      iconUrl: require('../asset/green marker.png'),
      // shadowUrl: iconShadow,
      iconSize: [60, 50],
      iconAnchor: [30, 50]
    });

const foodCustomIcon = new Icon({
  iconUrl: require('../asset/food marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const agriIcon = new Icon({
  iconUrl: require('../asset/agri marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const energyIcon = new Icon({
  iconUrl: require('../asset/energy marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const fashionIcon = new Icon({
  iconUrl: require('../asset/fashion marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const foodWasteAgriIcon = new Icon({
  iconUrl: require('../asset/food waste agri marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const housingIcon = new Icon({
  iconUrl: require('../asset/housing marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const hygieneIcon = new Icon({
  iconUrl: require('../asset/hygiene marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const manufFoodIcon = new Icon({
  iconUrl: require('../asset/manuf food marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const manufIcon = new Icon({
  iconUrl: require('../asset/manuf marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const manufRecycIcon = new Icon({
  iconUrl: require('../asset/manuf recycling marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const manufWasteIcon = new Icon({
  iconUrl: require('../asset/manuf waste marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const plastManufWasteIcon = new Icon({
  iconUrl: require('../asset/plast manuf waste marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const plastWasteRecycIcon = new Icon({
  iconUrl: require('../asset/plast waste recyc marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const plastManufIcon = new Icon({
  iconUrl: require('../asset/plastics manuf marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const plasticsIcon = new Icon({
  iconUrl: require('../asset/plastics marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const rawIcon = new Icon({
  iconUrl: require('../asset/raw marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const wasteIcon = new Icon({
  iconUrl: require('../asset/waste marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const wasteRawIcon = new Icon({
  iconUrl: require('../asset/waste raw marker.png'),
  // shadowUrl: iconShadow,
  iconSize: [60, 50],
  iconAnchor: [30, 50]
});

const Map = ({ countries, regional_csv, impactSelected, impact, sector, selectedOrigin }) => {  

  const mapStyle = {
    fillColor: "white",
    weight: 1,
    color: "white",
    fillOpacity: 1,
    opacity: 1
  };

  const popupStyle = {
    minWidth: 500,
    maxHeight: 300
  };

  const onEachCountry = (country, layer) => {
    layer.options.fillColor = country.properties.color;
    const name = country.properties.ADMIN;
    let aggrResultText = ""
    let aggrResultTextImpact = ""
    let resText = ""
    let regionalText = []
    if (country.properties.list !== [] && country.properties.list !== undefined && country.properties.aggrResults[0] !== [] && country.properties.aggrResults[0] !== undefined) {
      for (let i=0; i<country.properties.list.length;i++) {
        if (country.properties.aggrResults[0].aggr_impact !== 0) {
          aggrResultText = `${"Contribution of "}${name} to the selected supply chain as a fraction of global inputs: ${Number(country.properties.aggrResults[0].aggr_impact*100).toFixed(2)}${" %"}`
          aggrResultTextImpact = `${"Contribution of "}${name} to the selected supply chain as a fraction of the ${impact} required to meet ${selectedOrigin}'s final demand for ${sector}: ${Number(country.properties.aggrResults[0].aggr_impact*100).toFixed(2)}${" %"}`
          if (Number(country.properties.list[i].result/country.properties.aggrResults[0].aggr_sum*100).toFixed(2) !== NaN) {
            resText = resText.concat(`- ${country.properties.list[i].sector}: ${Number(country.properties.list[i].result/country.properties.aggrResults[0].aggr_sum*100).toFixed(2)} %<br>`);
          }
          if (impactSelected == false) {
            for (let j=0; j<regional_csv.length;j++) {
              if (country.properties.list[i].ISO_A2 == regional_csv[j].iso) {
                regionalText.push(`
                  Trade represented ${regional_csv[j].trade_share_2021}${" of GDP in "}${name}${" in 2021."}
                  </br>
                  <hr>
                  Environmental justice (<a target=_blank href=https://just2ce.eu/environmental-justice/>read more</a>)
                  </br>
                  <hr>
                  ${parseInt(regional_csv[j].n_cases_ejatlas)} <strong>Environmental Conflicts</strong> have been reported in ${name}, particularly in relation to <i>${regional_csv[j].ejatlas_cat_1}</i>, check out the <a target=_blank href=${regional_csv[j].ejatlas_weblink}>Environmental Justice Atlas</a>
                  </br>
                  <hr>
                  The <a target=_blank href=https://data.footprintnetwork.org/#/>Ecological Footprint</a> <strong>per Capita</strong> is ${regional_csv[j].footprint_per_capita}, this is ${regional_csv[j].k_col} times the resources and wastes that can be regenerated and absorb in the atmosphere.
                  </br>
                  <hr>
                  Labour (<a target=_blank href=https://just2ce.eu/labour/>read more</a>)
                  </br>
                  <hr>
                  The 2023 <strong>Global Rights Index</strong> is ${parseInt(regional_csv[j].gri)}, representing <i>${regional_csv[j].gri_meta}</i> (<a target=_blank href=${regional_csv[j].gri_weblink}>see more from the International Trade Union Confederation</a>)
                  </br>
                  <hr>
                  Gender Justice (<a target=_blank href=https://just2ce.eu/gender/>read more</a>)
                  </br>
                  <hr>
                  The <strong>proportion of time spent on unpaid domestic and care work</strong> is ${regional_csv[j].time_female} (female) and ${regional_csv[j].time_male} (male), as a percentage of a 24 hour day, as of ${parseInt(regional_csv[j].time_year)}.
                  </br>
                  The formal <strong>labour participation gap</strong> sees males leading by ${regional_csv[j].participation_gap} as of 2022.
                  </br>
                  <hr>
                  ${name} has ${regional_csv[j].un_women} of the <strong>legal frameworks that promote, enforce, and monitor gender equality</strong>, as determined by <a target=_blank href=https://www.unwomen.org/en/digital-library/publications/2022/09/progress-on-the-sustainable-development-goals-the-gender-snapshot-2022>UN Women</a>.
                  </br>
                  <hr>
                  As of 2016, the <strong>Gender Wage Gap</strong> was ${regional_csv[j].gender_pay_gap}.
                `)
              }
            }
          }
        } else {
          resText = "No impact detected";
        }
      }
    }
    const counts = {}
    regionalText.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; })
    if(resText !== "" && resText !== NaN && resText !== "No impact detected" && impactSelected == false) {
      layer.bindPopup(`
      <!-- <p style="text-align:left; font-size:160%; margin-top:0"> -->
      <b>${name}</b>
      <!-- </p> -->
      <br>
      <br>
      ${aggrResultText}
      <br>
      <hr> Top 5 sectors in the country which contribute to the selected supply chain: <br> ${resText} <hr> ${Object.keys(counts)}       
      `, popupStyle)
    } else if (resText !== "" && resText !== NaN && resText !== "No impact detected" && impactSelected == true) {
      layer.bindPopup(`
      <!-- <p style="text-align:left; font-size:160%; margin-top:0"> -->
      <b>${name}</b>
      <br>
      <br>
      <!--     <span style="float:right;"> -->
      <!--         <i>Impact view</i> -->
      <!--     </span> -->
      <!-- </p> -->
      ${aggrResultTextImpact}
      <br>
      <hr> Top 5 sectors in the country which contribute to the ${impact} in the selected supply chain: <br> ${resText} ${Object.keys(counts)}       
      `, popupStyle)
    } else if (resText == "No impact detected") {
      layer.bindPopup(`<b>${name}</b><br> ${"No impact detected"}`);
    } else {
      layer.bindPopup(`<b>${name}</b><br> ${"No data"}`);
    }
  };

  return (
    <MapContainer center={[45, 0]} zoom={1.5} minZoom={1.4} maxZoom={7} zoomControl={false} scrollWheelZoom={true}>
      <ZoomControl position='bottomright' />
      <TileLayer
          url='https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png'
            attribution= '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            maxZoom= {13}
            noWrap= {true}
            bounds={[[-90,-180], [90,180]]}
        />
      <GeoJSON
        style={mapStyle}
        data={countries}        
        key={JSON.stringify(countries)}
        onEachFeature={onEachCountry}
      />      
      <LayersControl position="topright">        
        {/* <LayersControl.Overlay name="Justice Layer">
          <FeatureGroup pathOptions={{ color: 'purple' }}>
            <Popup>Popup in FeatureGroup</Popup>
            <Circle center={[51.51, -0.06]} radius={200} />
            <Rectangle bounds={rectangle} />
          </FeatureGroup>
        </LayersControl.Overlay> */}
        <LayersControl.Overlay name="JUST2CE Case Studies">
          <FeatureGroup>
            <Marker position={[6.144592775012338, -0.16395372390747073]} icon={customIcon}>
              <Popup>
                <b>Ghana</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-agbogbloshie/">Agbogbloshie eWaste collectors</a></b><br/> Sector: Critical raw materials<br/>Partners involved: KUMASI HIVE
              </Popup>
            </Marker>
            <Marker position={[-20.05173366503917, 30.992675781250004]} icon={customIcon}>
              <Popup>
                <b>Zimbabwe</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-petrecozim-zimphos/">PetrecoZim & ZimPhos</a></b><br/> Sector: Plastics in a Circular Economy & Critical raw materials<br/>Partners involved: SIRDC
              </Popup>
            </Marker>
            <Marker position={[39.568872344643655, 15.8096923828125]} icon={customIcon}>
              <Popup>
                <b>Italy</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-e-waste-management-system-in-campania/">Campania Region e-waste management system</a></b><br/> Sector: Critical raw materials<br/>Partners involved: UNIPARTH
              </Popup>
            </Marker>
            <Marker position={[40.832899, -4.124716]} icon={customIcon}>
              <Popup>
                <b>Spain</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-algaenergy/">AlgaEnergy</a></b><br/> Sector: Energy, food and cosmetics<br/>Partners involved: UAB
              </Popup>
            </Marker>
            <Marker position={[40.106750, 18.691518]} icon={customIcon}>
              <Popup>
                <b>Italy</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/ex-ilva-taranto/">Ex-Ilva Taranto</a></b><br/> Sector: Steelmaking<br/>Partners involved: CES
              </Popup>
            </Marker>
            <Marker position={[52.381116, -0.888436]} icon={customIcon}>
              <Popup>
                <b>UK</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-sheffield-advanced-manufacturing-district/">Sheffield Advanced Manufacturing Park</a></b><br/> Sector: Advanced Manufacturing<br/>Partners involved: USFD
              </Popup>
            </Marker>
            <Marker position={[42.832899, -8.124716]} icon={customIcon}>
              <Popup>
                <b>Spain</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-jealsa-rianxeira-wesea/">Jealsa Rianxeira/Wesea</a></b><br/> Sector: Food production<br/>Partners involved: UAB
              </Popup>
            </Marker>
            <Marker position={[-30.495528, 23.005887]} icon={customIcon}>
              <Popup>
                <b>South Africa</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-lowerland/">Lowerland</a></b><br/> Sector: Food production<br/>Partners involved: UCT
              </Popup>
            </Marker>
            <Marker position={[31.323001, -6.703870]} icon={customIcon}>
              <Popup>
                <b>Morocco</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-recycling-systems-in-morocco-serp-recyclage-case-study/">Morocco Plastics Recycling Value Chain</a></b><br/> Sector: Plastics in a Circular Economy<br/>Partners involved: ARC
              </Popup>
            </Marker>
            <Marker position={[38.649999, -7.710733]} icon={customIcon}>
              <Popup>
                <b>Portugal</b><br/><b><a target="blank" href="https://just2ce.eu/case-studies/case-study-minga-montermor-coop/">Rede Cooperativa Integral Minga Montemor</a></b><br/> Sector: Food production & Housing<br/>Partners involved: CES
              </Popup>
            </Marker>
          </FeatureGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Just CE Practices of Interest">
          <FeatureGroup>
            <Marker position={[-17.901210, 32.5]} icon={foodCustomIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.899594699999987%2C30.99920440000001&z=8">Murambia Savanhu - Informal cooperative : Food waste recycling</a></b><br/> Sector: Food; Waste management<br/>Main circularity aspects: Recycling
                </Popup>
              </Marker>
              <Marker position={[-33.99291549999999, 18.512185299999995]} icon={foodCustomIcon}>
                <Popup>
                  <b>South Africa</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-33.99291549999999%2C18.512185299999995&z=8">FoodForward SA - keeping materials in use, diverting waste from landfill</a></b><br/> Sector: Food; Waste management<br/>Main circularity aspects: Reuse; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-34.003882600000004, 19.60432369999998]} icon={foodCustomIcon}>
                <Popup>
                  <b>South Africa</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-34.003882600000004%2C18.60432369999998&z=8">Inseco – diverting waste from landfill, using waste as resource</a></b><br/> Sector: Food; Waste management<br/>Main circularity aspects: Recycling; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-1.2617622000000144, 36.80156019999999]} icon={foodCustomIcon}>
                <Popup>
                  <b>Kenya</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-1.2617622000000144%2C36.80156019999999&z=8">SokoFresh – rethinking the business model</a></b><br/> Sector: Food; Waste management<br/>Main circularity aspects: Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-17.6, 31.4]} icon={plasticsIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.90117332999999%2C30.998499720000012&z=8">Benson Kaseke (informal cooperative representative)</a></b><br/> Sector: Plastics; Waste management<br/>Main circularity aspects: Environmental sustainability; Entry level recycling
                </Popup>
              </Marker>
              <Marker position={[-18.1, 31.5]} icon={plasticsIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.881251940000027%2C31.035596670000004&z=8">Petrecozim - Collecting and pre-processing of plastic waste – Entry level plastics recycling</a></b><br/> Sector: Plastics; Waste management<br/>Main circularity aspects: Environmental sustainability; Recycling
                </Popup>
              </Marker>
              <Marker position={[-18.5, 31.2]} icon={plasticsIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.83422443999998%2C31.049255560000013&z=8">Blessing Chihota Leading waste pickers – cardbox and plastics materials recovering</a></b><br/> Sector: Plastics; Waste management<br/>Main circularity aspects: Recycling
                </Popup>
              </Marker>
              <Marker position={[-27.204102800000005, 28.047305099999992]} icon={plasticsIcon}>
                <Popup>
                  <b>South Africa</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-26.204102800000005%2C28.047305099999992&z=8">African Reclaimers Organisation – use waste as resource</a></b><br/> Sector: Plastics; Waste management<br/>Main circularity aspects: Recycling; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-18.4, 30.8]} icon={rawIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.878017779999983%2C31.07032360999998&z=8">Austin Chikukwa, Makasa Joseph - Re-use</a></b><br/> Sector: Critical raw materials; Waste management<br/>Main circularity aspects: Reuse
                </Popup>
              </Marker>
              <Marker position={[-18, 30.2]} icon={rawIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.859189999999973%2C31.055413610000016&z=8">Betterfit Radiators and air conditioning - Lester Chizunza, Refurbishment</a></b><br/> Sector: Critical raw materials; Waste management<br/>Main circularity aspects: Reuse; Refurbishment
                </Popup>
              </Marker>
              <Marker position={[-1.2650930000000091, 38]} icon={rawIcon}>
                <Popup>
                  <b>Kenya</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-1.2650930000000091%2C36.9823233&z=8">WEEE Centre – using waste as resource</a></b><br/> Sector: Critical raw materials; Waste management<br/>Main circularity aspects: Remanufacturing; Recycling; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-17.2, 31.3]} icon={manufWasteIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.887272219999993%2C31.063871109999994&z=8">Patterson Millers - Recycling</a></b><br/> Sector: Manufacturing; Waste management<br/>Main circularity aspects: Recycling
                </Popup>
              </Marker>
              <Marker position={[-25.248537699999996, 27.854032300000018]} icon={manufWasteIcon}>
                <Popup>
                  <b>South Africa</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-26.248537699999996%2C27.854032300000018&z=8">Uku’hamba Prosthetics (Pty) Ltd – designing out waste, using waste as resource</a></b><br/> Sector: Manufacturing; Waste management<br/>Main circularity aspects: Remanufacturing; Recycling; Adoption of certification schemes and/or Life Cycle Assessment
                </Popup>
              </Marker>
              <Marker position={[-26.30544820000003, 31.136671499999984]} icon={manufWasteIcon}>
                <Popup>
                  <b>eSwatini</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-26.30544820000003%2C31.136671499999984&z=8">Ngwenya Glass (Pty) Ltd – use waste as resource</a></b><br/> Sector: Manufacturing; Waste management<br/>Main circularity aspects: Recycling; Environmental sustainability
                </Popup>
              </Marker>              
              <Marker position={[-18, 31.9]} icon={plastManufIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.863998059999986%2C31.051993890000006&z=8">Conductus Investments(Pvt Ltd) - Buying plastic waste and manufacturing plastic products, Mr Chikwaka</a></b><br/> Sector: Plastics; Manufacturing<br/>Main circularity aspects: Recycling; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-17.4, 31.8]} icon={plastManufIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.86416693999998%2C31.051999720000005&z=8">Moltim plastics (Pvt Ltd ) - Buying Plastic waste and manufacturing plastic products, Mr A Madyakanga</a></b><br/> Sector: Plastics; Manufacturing<br/>Main circularity aspects: Recycling
                </Popup>
              </Marker>
              <Marker position={[-17.874242780000007, 30.6]} icon={plastManufIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.874242780000007%2C31.00273167000001&z=8">Waverley plastics( pvt) ltd - J Makuni, Plastic processing</a></b><br/> Sector: Plastics; Manufacturing<br/>Main circularity aspects: Recycling
                </Popup>
              </Marker>
              <Marker position={[45.116176999999986, 7.742615000000006]} icon={wasteIcon}>
                <Popup>
                  <b>Italy</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=45.116176999999986%2C7.742615000000006&z=8">Astelav</a></b><br/> Sector: Waste management<br/>Main circularity aspects: Reuse; Remanufacturing; Repair
                </Popup>
              </Marker>
              <Marker position={[-17.2, 30.9]} icon={wasteIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.881344440000014%2C31.070829999999994&z=8">Shepherd Munanga, Informal refridgerator repairs and maintenance - Refurbishment</a></b><br/> Sector: Waste management<br/>Main circularity aspects: Remanufacturing; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[38.02036300000001, 12.53368]} icon={wasteIcon}>
                <Popup>
                  <b>Italy</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=38.02036300000001%2C12.53368&z=8">Calcestruzzi Ericina Libera</a></b><br/> Sector: Waste management<br/>Main circularity aspects: Reuse; Recycling; Environmental sustainability; Adoption of certification schemes and/or Life Cycle Assessment
                </Popup>
              </Marker>
              <Marker position={[-1.2601640000000103, 36.78196319999999]} icon={agriIcon}>
                <Popup>
                  <b>Kenya</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-1.2601640000000103%2C36.78196319999999&z=8">Hello Tractor – rethink the business model</a></b><br/> Sector: Agriculture<br/>Main circularity aspects: Leasing business model
                </Popup>
              </Marker>
              <Marker position={[45.433334, 10.983332999999984]} icon={fashionIcon}>
                <Popup>
                  <b>Italy</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=45.433334%2C10.983332999999984&z=8">Project Quid</a></b><br/> Sector: Fashion<br/>Main circularity aspects: Reuse; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[6.663686400000017, -1.6353740000000094]} icon={foodWasteAgriIcon}>
                <Popup>
                  <b>Ghana</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=6.663686400000017%2C-1.6353740000000094&z=8">AgriCentric Ventures – using waste as resource</a></b><br/> Sector: Food; Waste management; Agriculture<br/>Main circularity aspects: Environmental sustainability; Keeping materials circulating in the economy
                </Popup>
              </Marker>
              <Marker position={[-26.235639199999998, 29.086097099999994]} icon={housingIcon}>
                <Popup>
                  <b>South Africa</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-26.235639199999998%2C27.886097099999994&z=8">Hustlenomics – using waste as resource</a></b><br/> Sector: Housing<br/>Main circularity aspects: Designing for durability ; Recycling; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[40.34915899999999, 18.172072999999987]} icon={manufIcon}>
                <Popup>
                  <b>Italy</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=40.34915899999999%2C18.172072999999987&z=8">Officina Creativa</a></b><br/> Sector: Manufacturing<br/>Main circularity aspects: Reuse
                </Popup>
              </Marker>
              <Marker position={[38.116669, 13.366666999999985]} icon={manufFoodIcon}>
                <Popup>
                  <b>Italy</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=38.116669%2C13.366666999999985&z=8">Cotti in Fragranza project</a></b><br/> Sector: Manufacturing; Food<br/>Main circularity aspects: Environmental sustainability; Food produced on the basis of zero miles formula
                </Popup>
              </Marker>
              <Marker position={[-17.4, 30.6]} icon={manufRecycIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.887901390000014%2C31.062903890000022&z=8">Velveter Tissue Company - Re-manufacture</a></b><br/> Sector: Manufacturing; Recycling<br/>Main circularity aspects: Environmental sustainability; Recycling
                </Popup>
              </Marker>
              <Marker position={[-24.628207899999985, 25.923147099999984]} icon={hygieneIcon}>
                <Popup>
                  <b>Botswana</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-24.628207899999985%2C25.923147099999984&z=8">The Chosen – stretch the lifetime</a></b><br/> Sector: Personal Hygiene<br/>Main circularity aspects: Designing for durability; Reuse; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-17.85717139, 31.05000000000002]} icon={plastManufWasteIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.85717139%2C31.05000000000002&z=8">Farai Plastics – Chinese Company, Plastics Recycling</a></b><br/> Sector: Plastics; Manufacturing; Waste management<br/>Main circularity aspects: Recycling; Environmental sustainability
                </Popup>
              </Marker>
              <Marker position={[-18.89823056000002, 31.07589833000001]} icon={plastWasteRecycIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.99823056000002%2C31.07589833000001&z=8">Saints Plastics - Recycling</a></b><br/> Sector: Plastics; Waste management; Plastic recycling<br/>Main circularity aspects: Recycling
                </Popup>
              </Marker>
              <Marker position={[-16.8, 31.06519029999999]} icon={wasteRawIcon}>
                <Popup>
                  <b>Zimbabwe</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=-17.892968300000007%2C31.06519029999999&z=8">Tendai Car Breakers- informal P, Reuse</a></b><br/> Sector: Waste management; Critical raw materials<br/>Main circularity aspects: Reuse
                </Popup>
              </Marker>
              <Marker position={[0.22735279999999414, 32.32492360000001]} icon={energyIcon}>
                <Popup>
                  <b>Uganda</b><br/><b><a target="blank" href="https://www.google.com/maps/d/viewer?mid=1hQ2r7MM0bY_AQVB5E52MWaZc6FYBIbQ&femb=1&ll=0.22735279999999414%2C32.32492360000001&z=8">Tusafishe – rethinking the business model</a></b><br/> Sector: Water use; Energy<br/>Main circularity aspects: Environmental sustainability
                </Popup>
              </Marker>
          </FeatureGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  );
};

export default Map;