import React from "react";

const Legend = ({ legendItems }) => {
    return <div style={{
        display: "flex",
        alignItems: "stretch",
        padding: 0
    }}>
        {legendItems.map((item) => (
            <div key={item.title} style={{
                backgroundColor: item.color,
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: item.textColor,
                // height: '4.8vh',
                fontWeight: "bolder",
                fontSize: "1.1em"
            }}>
                <span>{item.title}</span>
            </div>
        ))}
    </div>
}

export default Legend;