export const COUNTRIES = [    
	{value: 'AT', label: 'Austria'},	
	{value:'BE', label: 'Belgium'},
	{value:'BG', label: 'Bulgaria'},	
	{value:'CY', label: 'Cyprus'},    
	{value:'CZ', label: 'Czech Republic'},
	{value:'DE', label: 'Germany'},
	{value:'DK', label: 'Denmark'},	
	{value:'EE', label: 'Estonia'},	
	{value:'ES', label: 'Spain'},	
	{value:'FI', label: 'Finland'},	
	{value:'FR', label: 'France'},	
	{value:'GR', label: 'Greece'},
	{value:'HR', label: 'Croatia'},
	{value:'HU', label: 'Hungary'},
	{value:'IE', label: 'Ireland'},
	{value:'IT', label: 'Italy'},	
	{value:'LT', label: 'Lithuania'},
	{value:'LU', label: 'Luxembourg'},
	{value:'LV', label: 'Latvia'},	
	{value:'MT', label: 'Malta'},	
	{value:'NL', label: 'Netherlands'},	
	{value:'PL', label: 'Poland'},		
	{value:'PT', label: 'Portugal'},
	{value:'RO', label: 'Romania'},
	{value:'SE', label: 'Sweden'},
	{value:'SI', label: 'Slovenia'},
	{value:'SK', label: 'Slovakia'},
    {value:'GB', label: 'United Kingdom (Great Britain)'},
    {value:'US', label: 'United States of America'},
    {value:'JP', label: 'Japan'},
    {value:'CN', label: 'China'},
    {value:'CA', label: 'Canada'},
    {value:'KR', label: 'Korea, Republic of'},
    {value:'BR', label: 'Brazil'},
    {value:'IN', label: 'India'},
    {value:'MX', label: 'Mexico'},
    {value:'RU', label: 'Russian Federation'},
    {value:'AU', label: 'Australia'},
    {value:'CH', label: 'Switzerland'},
    {value:'TR', label: 'Turkey'},
    {value:'TW', label: 'Taiwan, Province of China'},
    {value:'NO', label: 'Norway'},
    {value:'ID', label: 'Indonesia'},
    {value:'ZA', label: 'South Africa'},
    {value:'WA', label: 'WA'},
	{value:'WL', label: 'WL'},
	{value:'WE', label: 'WE'},
	{value:'WF', label: 'WF'},
	{value:'WM', label: 'WM'},

    // 'WA', 'WL', 'WF', 'WM' ??
]

export const IMPACTS = [
	{value: 'Value Added', label: 'Value Added'},
	{value: 'Employment', label: 'Employment'},
	{value: 'Employment hour', label: 'Employment hour'},
	{value: 'GHG emissions (GWP100) | Problem oriented approach: baseline (CML, 2001) | GWP100 (IPCC, 2007)', label: 'GHG emissions (GWP100) | Problem oriented approach: baseline (CML, 2001) | GWP100 (IPCC, 2007)'},
	{value: 'Human toxicity (USEtox) | USEtox2008 | CTUh (Rosenbaum et al., 2008)', label: 'Human toxicity (USEtox) | USEtox2008 | CTUh (Rosenbaum et al., 2008)'},
	{value: 'Fresh water Ecotoxicity (USEtox) | USEtox2008 | CTUe (Rosenbaum et al., 2008)', label: 'Fresh water Ecotoxicity (USEtox) | USEtox2008 | CTUe (Rosenbaum et al., 2008)'},
	{value: 'EPS | Damage Approach | EPS (Steen, 1999))', label: 'EPS | Damage Approach | EPS (Steen, 1999))'},
	{value: 'Carcinogenic effects on humans (H.A) | ECOINDICATOR 99 (H.A) | Carcinogenic effects on humans (H.A)', label: 'Carcinogenic effects on humans (H.A) | ECOINDICATOR 99 (H.A) | Carcinogenic effects on humans (H.A)'},
	{value: 'Respiratory effects on humans caused by organic substances (H.A) | ECOINDICATOR 99 (H.A) | Respiratory effects on humans caused by organic substances (H.A)', label: 'Respiratory effects on humans caused by organic substances (H.A) | ECOINDICATOR 99 (H.A) | Respiratory effects on humans caused by organic substances (H.A)'},
	{value: 'Respiratory effects on humans caused by inorganic substances (H.A) | ECOINDICATOR 99 (H.A) | Respiratory effects on humans caused by inorganic substances (H.A)', label: 'Respiratory effects on humans caused by inorganic substances (H.A) | ECOINDICATOR 99 (H.A) | Respiratory effects on humans caused by inorganic substances (H.A)'},
	{value: 'Damages to human health caused by climate change (H.A) | ECOINDICATOR 99 (H.A) | Damages to human health caused by climate change (H.A)', label: 'Damages to human health caused by climate change (H.A) | ECOINDICATOR 99 (H.A) | Damages to human health caused by climate change (H.A)'},
	{value: 'Damage to Ecosystem Quality caused by ecotoxic emissions (H.A) | ECOINDICATOR 99 (H.A) | Damage to Ecosystem Quality caused by ecotoxic emissions (H.A)', label: 'Damage to Ecosystem Quality caused by ecotoxic emissions (H.A) | ECOINDICATOR 99 (H.A) | Damage to Ecosystem Quality caused by ecotoxic emissions (H.A)'},
	{value: 'Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (H.A) | ECOINDICATOR 99 (H.A) | Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (H.A)', label: 'Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (H.A) | ECOINDICATOR 99 (H.A) | Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (H.A)'},
	{value: 'Carcinogenic effects on humans (E.E) | ECOINDICATOR 99 (E.E) | Carcinogenic effects on humans (E.E)', label: 'Carcinogenic effects on humans (E.E) | ECOINDICATOR 99 (E.E) | Carcinogenic effects on humans (E.E)'},
	{value: 'Respiratory effects on humans caused by organic substances (E.E) | ECOINDICATOR 99 (E.E) | Respiratory effects on humans caused by organic substances (E.E)', label: 'Respiratory effects on humans caused by organic substances (E.E) | ECOINDICATOR 99 (E.E) | Respiratory effects on humans caused by organic substances (E.E)'},
	{value: 'Respiratory effects on humans caused by inorganic substances (E.E) | ECOINDICATOR 99 (E.E) | Respiratory effects on humans caused by inorganic substances (E.E)', label: 'Respiratory effects on humans caused by inorganic substances (E.E) | ECOINDICATOR 99 (E.E) | Respiratory effects on humans caused by inorganic substances (E.E)'},
	{value: 'Damages to human health caused by climate change (E.E) | ECOINDICATOR 99 (E.E) | Damages to human health caused by climate change (E.E)', label: 'Damages to human health caused by climate change (E.E) | ECOINDICATOR 99 (E.E) | Damages to human health caused by climate change (E.E)'},
	{value: 'Damage to Ecosystem Quality caused by ecotoxic emissions (E.E)) | ECOINDICATOR 99 (E.E) | Damage to Ecosystem Quality caused by ecotoxic emissions (E.E))', label: 'Damage to Ecosystem Quality caused by ecotoxic emissions (E.E)) | ECOINDICATOR 99 (E.E) | Damage to Ecosystem Quality caused by ecotoxic emissions (E.E))'},
	{value: 'Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (E.E) | ECOINDICATOR 99 (E.E) | Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (E.E)', label: 'Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (E.E) | ECOINDICATOR 99 (E.E) | Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (E.E)'},
	{value: 'Carcinogenic effects on humans (I.I) | ECOINDICATOR 99 (I.I) | Carcinogenic effects on humans (I.I)', label: 'Carcinogenic effects on humans (I.I) | ECOINDICATOR 99 (I.I) | Carcinogenic effects on humans (I.I)'},
	{value: 'Respiratory effects on humans caused by organic substances (I.I) | ECOINDICATOR 99 (I.I) | Respiratory effects on humans caused by organic substances (I.I)', label: 'Respiratory effects on humans caused by organic substances (I.I) | ECOINDICATOR 99 (I.I) | Respiratory effects on humans caused by organic substances (I.I)'},
	{value: 'Respiratory effects on humans caused by inorganic substances (I.I) | ECOINDICATOR 99 (I.I) | Respiratory effects on humans caused by inorganic substances (I.I)', label: 'Respiratory effects on humans caused by inorganic substances (I.I) | ECOINDICATOR 99 (I.I) | Respiratory effects on humans caused by inorganic substances (I.I)'},
	{value: 'Damages to human health caused by climate change (I.I) | ECOINDICATOR 99 (I.I) | Damages to human health caused by climate change (I.I)', label: 'Damages to human health caused by climate change (I.I) | ECOINDICATOR 99 (I.I) | Damages to human health caused by climate change (I.I)'},
	{value: 'Damage to Ecosystem Quality caused by ecotoxic emissions (I.I) | ECOINDICATOR 99 (I.I) | Damage to Ecosystem Quality caused by ecotoxic emissions (I.I)', label: 'Damage to Ecosystem Quality caused by ecotoxic emissions (I.I) | ECOINDICATOR 99 (I.I) | Damage to Ecosystem Quality caused by ecotoxic emissions (I.I)'},
	{value: 'Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (I.I) | ECOINDICATOR 99 (I.I) | Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (I.I)', label: 'Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (I.I) | ECOINDICATOR 99 (I.I) | Damage to Ecosystem Quality caused by the combined effect of acidification and eutrophication (I.I)'},
	{value: 'Unused Domestic Extraction', label: 'Unused Domestic Extraction'},
	{value: 'Water Consumption Green - Agriculture', label: 'Water Consumption Green - Agriculture'},
	{value: 'Water Consumption Blue - Agriculture', label: 'Water Consumption Blue - Agriculture'},
	{value: 'Water Consumption Blue - Livestock', label: 'Water Consumption Blue - Livestock'},
	{value: 'Water Consumption Blue - Manufacturing', label: 'Water Consumption Blue - Manufacturing'},
	{value: 'Water Consumption Blue - Electricity', label: 'Water Consumption Blue - Electricity'},
	{value: 'Water Consumption Blue - Domestic', label: 'Water Consumption Blue - Domestic'},
	{value: 'Water Consumption Blue - Total', label: 'Water Consumption Blue - Total'},
	{value: 'Water Withdrawal Blue - Manufacturing', label: 'Water Withdrawal Blue - Manufacturing'},
	{value: 'Water Withdrawal Blue - Electricity', label: 'Water Withdrawal Blue - Electricity'},
	{value: 'Water Withdrawal Blue - Domestic', label: 'Water Withdrawal Blue - Domestic'},
	{value: 'Water Withdrawal Blue - Total', label: 'Water Withdrawal Blue - Total'},
	{value: 'Carbon dioxide (CO2) IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)', label: 'Carbon dioxide (CO2) IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)'},
	{value: 'Methane (CH4) IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)', label: 'Methane (CH4) IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)'},
	{value: 'Nitrous Oxide (N2O) IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)', label: 'Nitrous Oxide (N2O) IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)'},
	{value: 'Carbon dioxide (CO2) CO2EQ IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)', label: 'Carbon dioxide (CO2) CO2EQ IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)'},
	{value: 'Methane (CH4) CO2EQ IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)', label: 'Methane (CH4) CO2EQ IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)'},
	{value: 'Nitrous Oxide (N2O) CO2EQ IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)', label: 'Nitrous Oxide (N2O) CO2EQ IPCC categories 1 to 4 and 6 to 7 (excl land use, land use change and forestry)'},
	{value: 'Carbon dioxide (CO2) Fuel combustion and cement', label: 'Carbon dioxide (CO2) Fuel combustion and cement'},
	{value: 'Carbon dioxide (CO2) Fuel combustion', label: 'Carbon dioxide (CO2) Fuel combustion'},
	{value: 'GHG emissions (GWP100) | Problem oriented approach: baseline (CML, 1999) | GWP100 (IPCC, 2007)', label: 'GHG emissions (GWP100) | Problem oriented approach: baseline (CML, 1999) | GWP100 (IPCC, 2007)'},
	{value: 'GHG emissions (GWP100min) | Problem oriented approach: non baseline (CML, 1999) | net GWP100 min(Houghton et al., 2001)', label: 'GHG emissions (GWP100min) | Problem oriented approach: non baseline (CML, 1999) | net GWP100 min(Houghton et al., 2001)'},
	{value: 'GHG emissions (GWP100max) | Problem oriented approach: non baseline (CML, 1999) | net GWP100 max(Houghton et al., 2001)', label: 'GHG emissions (GWP100max) | Problem oriented approach: non baseline (CML, 1999) | net GWP100 max(Houghton et al., 2001)'},
	{value: 'GHG emissions (GWP20) | Problem oriented approach: non baseline (CML, 1999) | GWP20 (IPCC, 2007)', label: 'GHG emissions (GWP20) | Problem oriented approach: non baseline (CML, 1999) | GWP20 (IPCC, 2007)'},
	{value: 'GHG emissions (GWP500) | Problem oriented approach: non baseline (CML, 1999) | GWP500 (IPCC, 2007)', label: 'GHG emissions (GWP500) | Problem oriented approach: non baseline (CML, 1999) | GWP500 (IPCC, 2007)'},
	{value: 'ozone layer depletion (ODP steady state) | Problem oriented approach: baseline (CML, 1999) | ODP steady state (WMO, 2003)', label: 'ozone layer depletion (ODP steady state) | Problem oriented approach: baseline (CML, 1999) | ODP steady state (WMO, 2003)'},
	{value: 'human toxicity (HTP inf) | Problem oriented approach: baseline (CML, 1999) | HTP inf. (Huijbregts, 1999 & 2000)', label: 'human toxicity (HTP inf) | Problem oriented approach: baseline (CML, 1999) | HTP inf. (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater aquatic ecotoxicity (FAETP inf) | Problem oriented approach: baseline (CML, 1999) | FAETP inf. (Huijbregts, 1999 & 2000)', label: 'Freshwater aquatic ecotoxicity (FAETP inf) | Problem oriented approach: baseline (CML, 1999) | FAETP inf. (Huijbregts, 1999 & 2000)'},
	{value: 'Marine aquatic ecotoxicity (MAETP inf) | Problem oriented approach: baseline (CML, 1999) | MAETP inf. (Huijbregts, 1999 & 2000)', label: 'Marine aquatic ecotoxicity (MAETP inf) | Problem oriented approach: baseline (CML, 1999) | MAETP inf. (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater sedimental ecotoxicity (FSETP inf) | Problem oriented approach: non baseline (CML, 1999) | FSETP inf. (Huijbregts, 1999 & 2000)', label: 'Freshwater sedimental ecotoxicity (FSETP inf) | Problem oriented approach: non baseline (CML, 1999) | FSETP inf. (Huijbregts, 1999 & 2000)'},
	{value: 'Marine sedimental ecotoxicity (MSETP inf) | Problem oriented approach: non baseline (CML, 1999) | MSETP inf. (Huijbregts, 1999 & 2000)', label: 'Marine sedimental ecotoxicity (MSETP inf) | Problem oriented approach: non baseline (CML, 1999) | MSETP inf. (Huijbregts, 1999 & 2000)'},
	{value: 'Terrestrial ecotoxicity (TETP inf) | Problem oriented approach: baseline (CML, 1999) | TETP inf.(Huijbregts, 1999 & 2000)', label: 'Terrestrial ecotoxicity (TETP inf) | Problem oriented approach: baseline (CML, 1999) | TETP inf.(Huijbregts, 1999 & 2000)'},
	{value: 'human toxicity (HTP20) | Problem oriented approach: non baseline (CML, 1999) | HTP 20 (Huijbregts, 1999 & 2000)', label: 'human toxicity (HTP20) | Problem oriented approach: non baseline (CML, 1999) | HTP 20 (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater aquatic ecotoxicity (FAETP20) | Problem oriented approach: non baseline (CML, 1999) | FAETP 20 (Huijbregts, 1999 & 2000)', label: 'Freshwater aquatic ecotoxicity (FAETP20) | Problem oriented approach: non baseline (CML, 1999) | FAETP 20 (Huijbregts, 1999 & 2000)'},
	{value: 'Marine aquatic ecotoxicity (MAETP20) | Problem oriented approach: non baseline (CML, 1999) | MAETP 20 (Huijbregts, 1999 & 2000)', label: 'Marine aquatic ecotoxicity (MAETP20) | Problem oriented approach: non baseline (CML, 1999) | MAETP 20 (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater sedimental ecotoxicity (FSETP20) | Problem oriented approach: non baseline (CML, 1999) | FSETP 20 (Huijbregts, 1999 & 2000)', label: 'Freshwater sedimental ecotoxicity (FSETP20) | Problem oriented approach: non baseline (CML, 1999) | FSETP 20 (Huijbregts, 1999 & 2000)'},
	{value: 'Marine sedimental ecotoxicity (MSETP20) | Problem oriented approach: non baseline (CML, 1999) | MSETP 20 (Huijbregts, 1999 & 2000)', label: 'Marine sedimental ecotoxicity (MSETP20) | Problem oriented approach: non baseline (CML, 1999) | MSETP 20 (Huijbregts, 1999 & 2000)'},
	{value: 'Terrestrial ecotoxicity (TETP20) | Problem oriented approach: non baseline (CML, 1999) | TETP 20 (Huijbregts, 1999 & 2000)', label: 'Terrestrial ecotoxicity (TETP20) | Problem oriented approach: non baseline (CML, 1999) | TETP 20 (Huijbregts, 1999 & 2000)'},
	{value: 'human toxicity (HTP100) | Problem oriented approach: non baseline (CML, 1999) | HTP 100 (Huijbregts, 1999 & 2000)', label: 'human toxicity (HTP100) | Problem oriented approach: non baseline (CML, 1999) | HTP 100 (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater aquatic ecotoxicity (FAETP100) | Problem oriented approach: non baseline (CML, 1999) | FAETP 100 (Huijbregts, 1999 & 2000)', label: 'Freshwater aquatic ecotoxicity (FAETP100) | Problem oriented approach: non baseline (CML, 1999) | FAETP 100 (Huijbregts, 1999 & 2000)'},
	{value: 'Marine aquatic ecotoxicity (MAETP100) | Problem oriented approach: non baseline (CML, 1999) | MAETP 100 (Huijbregts, 1999 & 2000)', label: 'Marine aquatic ecotoxicity (MAETP100) | Problem oriented approach: non baseline (CML, 1999) | MAETP 100 (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater sedimental ecotoxicity (FSETP100) | Problem oriented approach: non baseline (CML, 1999) | FSETP 100 (Huijbregts, 1999 & 2000)', label: 'Freshwater sedimental ecotoxicity (FSETP100) | Problem oriented approach: non baseline (CML, 1999) | FSETP 100 (Huijbregts, 1999 & 2000)'},
	{value: 'Marine sedimental ecotoxicity (MSETP100) | Problem oriented approach: non baseline (CML, 1999) | MSETP 100 (Huijbregts, 1999 & 2000)', label: 'Marine sedimental ecotoxicity (MSETP100) | Problem oriented approach: non baseline (CML, 1999) | MSETP 100 (Huijbregts, 1999 & 2000)'},
	{value: 'Terrestrial ecotoxicity (TETP100) | Problem oriented approach: non baseline (CML, 1999) | TETP 100 (Huijbregts, 1999 & 2000)', label: 'Terrestrial ecotoxicity (TETP100) | Problem oriented approach: non baseline (CML, 1999) | TETP 100 (Huijbregts, 1999 & 2000)'},
	{value: 'human toxicity (HTP500) | Problem oriented approach: non baseline (CML, 1999) | HTP 500 (Huijbregts, 1999 & 2000)', label: 'human toxicity (HTP500) | Problem oriented approach: non baseline (CML, 1999) | HTP 500 (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater aquatic ecotoxicity (FAETP500) | Problem oriented approach: non baseline (CML, 1999) | FAETP 500 (Huijbregts, 1999 & 2000)', label: 'Freshwater aquatic ecotoxicity (FAETP500) | Problem oriented approach: non baseline (CML, 1999) | FAETP 500 (Huijbregts, 1999 & 2000)'},
	{value: 'Marine aquatic ecotoxicity (MAETP500) | Problem oriented approach: non baseline (CML, 1999) | MAETP 500 (Huijbregts, 1999 & 2000)', label: 'Marine aquatic ecotoxicity (MAETP500) | Problem oriented approach: non baseline (CML, 1999) | MAETP 500 (Huijbregts, 1999 & 2000)'},
	{value: 'Freshwater sedimental ecotoxicity (FSETP500) | Problem oriented approach: non baseline (CML, 1999) | FSETP 500 (Huijbregts, 1999 & 2000)', label: 'Freshwater sedimental ecotoxicity (FSETP500) | Problem oriented approach: non baseline (CML, 1999) | FSETP 500 (Huijbregts, 1999 & 2000)'},
	{value: 'Marine sedimental ecotoxicity (MSETP500) | Problem oriented approach: non baseline (CML, 1999) | MSETP 500 (Huijbregts, 1999 & 2000)', label: 'Marine sedimental ecotoxicity (MSETP500) | Problem oriented approach: non baseline (CML, 1999) | MSETP 500 (Huijbregts, 1999 & 2000)'},
	{value: 'Terrestrial ecotoxicity (TETP500) | Problem oriented approach: non baseline (CML, 1999) | TETP 500 (Huijbregts, 1999 & 2000) TETP 500', label: 'Terrestrial ecotoxicity (TETP500) | Problem oriented approach: non baseline (CML, 1999) | TETP 500 (Huijbregts, 1999 & 2000) TETP 500'},
	{value: 'photochemical oxidation (high NOx) | Problem oriented approach: baseline (CML, 1999) | POCP (Jenkin & Hayman, 1999; Derwent et al. 1998; high NOx)', label: 'photochemical oxidation (high NOx) | Problem oriented approach: baseline (CML, 1999) | POCP (Jenkin & Hayman, 1999; Derwent et al. 1998; high NOx)'},
	{value: 'photochemical oxidation (low NOx) | Problem oriented approach: non baseline (CML, 1999) | POCP (Andersson-Skï¿½ld et al. 1992; low NOx)', label: 'photochemical oxidation (low NOx) | Problem oriented approach: non baseline (CML, 1999) | POCP (Andersson-Skï¿½ld et al. 1992; low NOx)'},
	{value: 'photochemical oxidation (MIR; very high NOx) | Problem oriented approach: non baseline (CML, 1999) | MIR 1997; very high NOx (Carter, 1994, 1997, 1998;Carter, Pierce, Luo &  Malkina, 1995 )', label: 'photochemical oxidation (MIR; very high NOx) | Problem oriented approach: non baseline (CML, 1999) | MIR 1997; very high NOx (Carter, 1994, 1997, 1998;Carter, Pierce, Luo &  Malkina, 1995 )'},
	{value: 'photochemical oxidation (MOIR; high NOx) | Problem oriented approach: non baseline (CML, 1999) | MOIR; high NOx (Carter, 1994, 1997, 1998;Carter, Pierce, Luo &  Malkina, 1995 )', label: 'photochemical oxidation (MOIR; high NOx) | Problem oriented approach: non baseline (CML, 1999) | MOIR; high NOx (Carter, 1994, 1997, 1998;Carter, Pierce, Luo &  Malkina, 1995 )'},
	{value: 'photochemical oxidation (EBIR; low NOx) | Problem oriented approach: non baseline (CML, 1999) | EBIR; low NOx (Carter, 1994, 1997, 1998;Carter, Pierce, Luo &  Malkina, 1995 )', label: 'photochemical oxidation (EBIR; low NOx) | Problem oriented approach: non baseline (CML, 1999) | EBIR; low NOx (Carter, 1994, 1997, 1998;Carter, Pierce, Luo &  Malkina, 1995 )'},
	{value: 'acidification (incl. fate, average Europe total, A&B) | Problem oriented approach: baseline (CML, 1999) | AP ( Huijbregts, 1999; average Europe total, A&B)', label: 'acidification (incl. fate, average Europe total, A&B) | Problem oriented approach: baseline (CML, 1999) | AP ( Huijbregts, 1999; average Europe total, A&B)'},
	{value: 'acidification (fate not incl.) | Problem oriented approach: non baseline (CML, 1999) | AP (Hauschild & Wenzel (1998)', label: 'acidification (fate not incl.) | Problem oriented approach: non baseline (CML, 1999) | AP (Hauschild & Wenzel (1998)'},
	{value: 'eutrophication (fate not incl.) | Problem oriented approach: baseline (CML, 1999) | EP (Heijungs et al. 1992))', label: 'eutrophication (fate not incl.) | Problem oriented approach: baseline (CML, 1999) | EP (Heijungs et al. 1992))'},
	{value: 'eutrophication (incl. fate, average Europe total, A&B) | Problem oriented approach: non baseline (CML, 1999) | EP ( Huijbregts, 1999; average Europe total, A&B)', label: 'eutrophication (incl. fate, average Europe total, A&B) | Problem oriented approach: non baseline (CML, 1999) | EP ( Huijbregts, 1999; average Europe total, A&B)'},
	{value: 'odour | Problem oriented approach: non baseline (CML, 1999) | 1/OTV', label: 'odour | Problem oriented approach: non baseline (CML, 1999) | 1/OTV'},
	{value: 'Climate change midpoint | ILCD recommended CF | Global warming potential 100 years', label: 'Climate change midpoint | ILCD recommended CF | Global warming potential 100 years'},
	{value: 'Climate change endpoint, human health | ILCD recommended CF | Disability Adjusted Life Years (DALY)', label: 'Climate change endpoint, human health | ILCD recommended CF | Disability Adjusted Life Years (DALY)'},
	{value: 'Climate change endpoint, ecosystems | ILCD recommended CF | Potentially Disappeared Fraction of species (PDF)', label: 'Climate change endpoint, ecosystems | ILCD recommended CF | Potentially Disappeared Fraction of species (PDF)'},
	{value: 'Human toxicity midpoint, cancer effects | ILCD recommended CF | Comparative Toxic Unit for human (CTUh)', label: 'Human toxicity midpoint, cancer effects | ILCD recommended CF | Comparative Toxic Unit for human (CTUh)'},
	{value: 'Human toxicity midpoint, non-cancer effects | ILCD recommended CF | Comparative Toxic Unit for human (CTUh)', label: 'Human toxicity midpoint, non-cancer effects | ILCD recommended CF | Comparative Toxic Unit for human (CTUh)'},
	{value: 'Human toxicity endpoint, cancer effects | ILCD recommended CF | Disability Adjusted Life Years (DALY)', label: 'Human toxicity endpoint, cancer effects | ILCD recommended CF | Disability Adjusted Life Years (DALY)'},
	{value: 'Human toxicity endpoint, non-cancer effects | ILCD recommended CF | Disability Adjusted Life Years (DALY)', label: 'Human toxicity endpoint, non-cancer effects | ILCD recommended CF | Disability Adjusted Life Years (DALY)'},
	{value: 'Particulate matter/Respiratory inorganics midpoint | ILCD recommended CF | emission-weighed average PM2.5 equivalent', label: 'Particulate matter/Respiratory inorganics midpoint | ILCD recommended CF | emission-weighed average PM2.5 equivalent'},
	{value: 'Particulate matter/Respiratory inorganics endpoint | ILCD recommended CF | Disability Adjusted Life Years (DALY)', label: 'Particulate matter/Respiratory inorganics endpoint | ILCD recommended CF | Disability Adjusted Life Years (DALY)'},
	{value: 'Photochemical ozone formation midpoint, human health | ILCD recommended CF | Photochemical ozone creation potential (POCP)', label: 'Photochemical ozone formation midpoint, human health | ILCD recommended CF | Photochemical ozone creation potential (POCP)'},
	{value: 'Photochemical ozone formation endpoint, human health | ILCD recommended CF | Disability Adjusted Life Years (DALY)', label: 'Photochemical ozone formation endpoint, human health | ILCD recommended CF | Disability Adjusted Life Years (DALY)'},
	{value: 'Acidification midpoint | ILCD recommended CF | Accumulated Exceedance (AE)', label: 'Acidification midpoint | ILCD recommended CF | Accumulated Exceedance (AE)'},
	{value: 'Acidification endpoint | ILCD recommended CF | Change in potentially not occuring fraction of plant species per change in base saturation', label: 'Acidification endpoint | ILCD recommended CF | Change in potentially not occuring fraction of plant species per change in base saturation'},
	{value: 'Eutrophication terrestrial midpoint | ILCD recommended CF | Accumulated Exceedance (AE)', label: 'Eutrophication terrestrial midpoint | ILCD recommended CF | Accumulated Exceedance (AE)'},
	{value: 'Eutrophication marine midpoint | ILCD recommended CF | Potentially Disappeared Fraction of species (PDF)', label: 'Eutrophication marine midpoint | ILCD recommended CF | Potentially Disappeared Fraction of species (PDF)'},
	{value: 'Ecotoxicity freshwater midpoint | ILCD recommended CF | Comparative Toxic Unit for ecosystems (CTUe)', label: 'Ecotoxicity freshwater midpoint | ILCD recommended CF | Comparative Toxic Unit for ecosystems (CTUe)'},
	{value: 'Ecotoxicity freshwater endpoint | ILCD recommended CF | Potentially Disappeared Fraction of species (PDF)', label: 'Ecotoxicity freshwater endpoint | ILCD recommended CF | Potentially Disappeared Fraction of species (PDF)'},
	{value: 'GHG emissions AR5 (GWP100) | GWP100 (IPCC, 2010)', label: 'GHG emissions AR5 (GWP100) | GWP100 (IPCC, 2010)'},
	{value: 'Nitrogen', label: 'Nitrogen'},
	{value: 'Phosphorous', label: 'Phosphorous'},
	{value: 'PM10', label: 'PM10'},
	{value: 'PM25', label: 'PM25'},
	{value: 'SOx', label: 'SOx'},
	{value: 'NOx', label: 'NOx'},
	{value: 'Domestic Extraction Used - Crop and Crop Residue', label: 'Domestic Extraction Used - Crop and Crop Residue'},
	{value: 'Domestic Extraction Used - Grazing and Fodder', label: 'Domestic Extraction Used - Grazing and Fodder'},
	{value: 'Domestic Extraction Used - Forestry and Timber', label: 'Domestic Extraction Used - Forestry and Timber'},
	{value: 'Domestic Extraction Used â Fisheries', label: 'Domestic Extraction Used â Fisheries'},        //******************* */
	{value: 'Domestic Extraction Used - Non-metalic Minerals', label: 'Domestic Extraction Used - Non-metalic Minerals'},
	{value: 'Domestic Extraction Used - Iron Ore', label: 'Domestic Extraction Used - Iron Ore'},
	{value: 'Domestic Extraction Used - Non-ferous metal ores', label: 'Domestic Extraction Used - Non-ferous metal ores'},
	{value: 'Unused Domestic Extraction - Crop and Crop Residue', label: 'Unused Domestic Extraction - Crop and Crop Residue'},
	{value: 'Unused Domestic Extraction - Grazing and Fodder', label: 'Unused Domestic Extraction - Grazing and Fodder'},
	{value: 'Unused Domestic Extraction - Forestry and Timber', label: 'Unused Domestic Extraction - Forestry and Timber'},
	{value: 'Unused Domestic Extraction â Fisheries', label: 'Unused Domestic Extraction â Fisheries'},      /********************** */
	{value: 'Unused Domestic Extraction - Coal and Peat', label: 'Unused Domestic Extraction - Coal and Peat'},
	{value: 'Unused Domestic Extraction - Oil and Gas', label: 'Unused Domestic Extraction - Oil and Gas'},
	{value: 'Unused Domestic Extraction - Non-metalic Minerals', label: 'Unused Domestic Extraction - Non-metalic Minerals'},
	{value: 'Unused Domestic Extraction - Iron Ore', label: 'Unused Domestic Extraction - Iron Ore'},
	{value: 'Unused Domestic Extraction - Non-ferous metal ores', label: 'Unused Domestic Extraction - Non-ferous metal ores'},
	{value: 'Land use Crop, Forest, Pasture', label: 'Land use Crop, Forest, Pasture'},
]

export const IMPACTS_NEW = [
	{value: 'Employment', label: 'Employment'},
	{value: 'GHG emissions (GWP100) | Problem oriented approach: baseline (CML, 2001) | GWP100 (IPCC, 2007)', label: 'GHG emissions (GWP100) | Problem oriented approach: baseline (CML, 2001) | GWP100 (IPCC, 2007)'},
	{value: 'Water Consumption Blue - Total', label: 'Water Consumption Blue - Total'},
	{value: 'ozone layer depletion (ODP steady state) | Problem oriented approach: baseline (CML, 1999) | ODP steady state (WMO, 2003)', label: 'ozone layer depletion (ODP steady state) | Problem oriented approach: baseline (CML, 1999) | ODP steady state (WMO, 2003)'},
	{value: 'SOx', label: 'SOx'},
	{value: 'Land use Crop, Forest, Pasture', label: 'Land use Crop, Forest, Pasture'},
	{value: 'Employment: Male', label: 'Employment: Male'},
	{value: 'Employment: Female', label: 'Employment: Female'},
	{value: 'Employment: Vulnerable employment', label: 'Employment: Vulnerable Employment'},
	{value: 'Raw Domestic Extraction Used', label: 'Raw Domestic Extraction Used'},
	{value: 'Total Domestic Extraction', label: 'Total Domestic Extraction'},
]

export const SECTORS_PXP = [
	{ value: "Paddy rice", label: "Paddy rice" },
  	{ value: "Wheat", label: "Wheat" },
  	{ value: "Cereal grains nec", label: "Cereal grains nec" },
	{ value: "Vegetables, fruit, nuts", label: "Vegetables, fruit, nuts" },
	{ value: "Oil seeds", label: "Oil seeds" },
	{ value: "Sugar cane, sugar beet", label: "Sugar cane, sugar beet" },
	{ value: "Plant-based fibers", label: "Plant-based fibers" },
	{ value: "Crops nec", label: "Crops nec" },
	{ value: "Cattle", label: "Cattle" },
	{ value: "Pigs", label: "Pigs" },
	{ value: "Poultry", label: "Poultry" },
	{ value: "Meat animals nec", label: "Meat animals nec" },
	{ value: "Animal products nec", label: "Animal products nec" },
	{ value: "Raw milk", label: "Raw milk" },
	{ value: "Wool, silk-worm cocoons", label: "Wool, silk-worm cocoons" },
	{ value: "Manure (conventional treatment)", label: "Manure (conventional treatment)" },
	{ value: "Manure (biogas treatment)", label: "Manure (biogas treatment)" },
	{ value: "Products of forestry, logging and related services (02)", label: "Products of forestry, logging and related services (02)" },
	{ value: "Fish and other fishing products; services incidental of fishing (05)", label: "Fish and other fishing products; services incidental of fishing (05)" },
	{ value: "Anthracite", label: "Anthracite" },
	{ value: "Coking Coal", label: "Coking Coal" },
	{ value: "Other Bituminous Coal", label: "Other Bituminous Coal" },
	{ value: "Sub-Bituminous Coal", label: "Sub-Bituminous Coal" },
	{ value: "Patent Fuel", label: "Patent Fuel" },
	{ value: "Lignite/Brown Coal", label: "Lignite/Brown Coal" },
	{ value: "BKB/Peat Briquettes", label: "BKB/Peat Briquettes" },
	{ value: "Peat", label: "Peat" },
	{ value: "Crude petroleum and services related to crude oil extraction, excluding surveying", label: "Crude petroleum and services related to crude oil extraction, excluding surveying" },
	{ value: "Natural gas and services related to natural gas extraction, excluding surveying", label: "Natural gas and services related to natural gas extraction, excluding surveying" },
	{ value: "Natural Gas Liquids", label: "Natural Gas Liquids" },
	{ value: "Other Hydrocarbons", label: "Other Hydrocarbons" },
	{ value: "Uranium and thorium ores (12)", label: "Uranium and thorium ores (12)" },
	{ value: "Iron ores", label: "Iron ores" },
	{ value: "Copper ores and concentrates", label: "Copper ores and concentrates" },
	{ value: "Nickel ores and concentrates", label: "Nickel ores and concentrates" },
	{ value: "Aluminium ores and concentrates", label: "Aluminium ores and concentrates" },
	{ value: "Precious metal ores and concentrates", label: "Precious metal ores and concentrates" },
	{ value: "Lead, zinc and tin ores and concentrates", label: "Lead, zinc and tin ores and concentrates" },
	{ value: "Other non-ferrous metal ores and concentrates", label: "Other non-ferrous metal ores and concentrates" },
	{ value: "Stone", label: "Stone" },
	{ value: "Sand and clay", label: "Sand and clay" },
	{ value: "Chemical and fertilizer minerals, salt and other mining and quarrying products n.e.c.", label: "Chemical and fertilizer minerals, salt and other mining and quarrying products n.e.c." },
	{ value: "Products of meat cattle", label: "Products of meat cattle" },
	{ value: "Products of meat pigs", label: "Products of meat pigs" },
	{ value: "Products of meat poultry", label: "Products of meat poultry" },
	{ value: "Meat products nec", label: "Meat products nec" },
	{ value: "products of Vegetable oils and fats", label: "products of Vegetable oils and fats" },
	{ value: "Dairy products", label: "Dairy products" },
	{ value: "Processed rice", label: "Processed rice" },
	{ value: "Sugar", label: "Sugar" },
	{ value: "Food products nec", label: "Food products nec" },
	{ value: "Beverages", label: "Beverages" },
	{ value: "Fish products", label: "Fish products" },
	{ value: "Tobacco products (16)", label: "Tobacco products (16)" },
	{ value: "Textiles (17)", label: "Textiles (17)" },
	{ value: "Wearing apparel; furs (18)", label: "Wearing apparel; furs (18)" },
	{ value: "Leather and leather products (19)", label: "Leather and leather products (19)" },
	{ value: "Wood and products of wood and cork (except furniture); articles of straw and plaiting materials (20)", label: "Wood and products of wood and cork (except furniture); articles of straw and plaiting materials (20)" },
	{ value: "Wood material for treatment, Re-processing of secondary wood material into new wood material", label: "Wood material for treatment, Re-processing of secondary wood material into new wood material" },
	{ value: "Pulp", label: "Pulp" },
	{ value: "Secondary paper for treatment, Re-processing of secondary paper into new pulp", label: "Secondary paper for treatment, Re-processing of secondary paper into new pulp" },
	{ value: "Paper and paper products", label: "Paper and paper products" },
	{ value: "Printed matter and recorded media (22)", label: "Printed matter and recorded media (22)" },
	{ value: "Coke Oven Coke", label: "Coke Oven Coke" },
	{ value: "Gas Coke", label: "Gas Coke" },
	{ value: "Coal Tar", label: "Coal Tar" },
	{ value: "Motor Gasoline", label: "Motor Gasoline" },
	{ value: "Aviation Gasoline", label: "Aviation Gasoline" },
	{ value: "Gasoline Type Jet Fuel", label: "Gasoline Type Jet Fuel" },
	{ value: "Kerosene Type Jet Fuel", label: "Kerosene Type Jet Fuel" },
	{ value: "Kerosene", label: "Kerosene" },
	{ value: "Gas/Diesel Oil", label: "Gas/Diesel Oil" },
	{ value: "Heavy Fuel Oil", label: "Heavy Fuel Oil" },
	{ value: "Refinery Gas", label: "Refinery Gas" },
	{ value: "Liquefied Petroleum Gases (LPG)", label: "Liquefied Petroleum Gases (LPG)" },
	{ value: "Refinery Feedstocks", label: "Refinery Feedstocks" },
	{ value: "Ethane", label: "Ethane" },
	{ value: "Naphtha", label: "Naphtha" },
	{ value: "White Spirit & SBP", label: "White Spirit & SBP" },
	{ value: "Lubrificants", label: "Lubrificants" },
	{ value: "Bitumen", label: "Bitumen" },
	{ value: "Paraffin Waxes", label: "Paraffin Waxes" },
	{ value: "Petroleum Coke", label: "Petroleum Coke" },
	{ value: "Non-specified Petroleum Products", label: "Non-specified Petroleum Products" },
	{ value: "Nuclear fuel", label: "Nuclear fuel" },
	{ value: "Plastics, basic", label: "Plastics, basic" },
	{ value: "Secondary plastic for treatment, Re- processing of secondary plastic into new plastic", label: "Secondary plastic for treatment, Re- processing of secondary plastic into new plastic" },
	{ value: "N-fertiliser", label: "N-fertiliser" },
	{ value: "P- and other fertiliser", label: "P- and other fertiliser" },
	{ value: "Chemicals nec", label: "Chemicals nec" },
	{ value: "Charcoal", label: "Charcoal" },
	{ value: "Addities/Blending Components", label: "Addities/Blending Components" },
	{ value: "Biogasoline", label: "Biogasoline" },
	{ value: "Biodiesels", label: "Biodiesels" },
	{ value: "Other Liquid Biofuels", label: "Other Liquid Biofuels" },
	{ value: "Rubber and plastic products (25)", label: "Rubber and plastic products (25)" },
	{ value: "Glass and glass products", label: "Glass and glass products" },
	{ value: "Secondary glass for treatment, Re- processing of secondary glass into new glass", label: "Secondary glass for treatment, Re- processing of secondary glass into new glass" },
	{ value: "Ceramic goods", label: "Ceramic goods" },
	{ value: "Bricks, tiles and construction products, in baked clay", label: "Bricks, tiles and construction products, in baked clay" },
	{ value: "Cement, lime and plaster", label: "Cement, lime and plaster" },
	{ value: "Ash for treatment, Re- processing of ash into clinker", label: "Ash for treatment, Re- processing of ash into clinker" },
	{ value: "Other non-metallic mineral products", label: "Other non-metallic mineral products" },
	{ value: "Basic iron and steel and of ferro-alloys and first products thereof", label: "Basic iron and steel and of ferro-alloys and first products thereof" },
	{ value: "Secondary steel for treatment, Re-processing of secondary steel into new steel", label: "Secondary steel for treatment, Re-processing of secondary steel into new steel" },
	{ value: "Precious metals", label: "Precious metals" },
	{ value: "Secondary precious metals for treatment, Re-processing of secondary precious metals into new precious metals", label: "Secondary precious metals for treatment, Re-processing of secondary precious metals into new precious metals" },
	{ value: "Aluminium and aluminium products", label: "Aluminium and aluminium products" },
	{ value: "Secondary aluminium for treatment, Re-processing of secondary aluminium into new aluminium", label: "Secondary aluminium for treatment, Re-processing of secondary aluminium into new aluminium" },
	{ value: "Lead, zinc and tin and products thereof", label: "Lead, zinc and tin and products thereof" },
	{ value: "Secondary lead for treatment, Re-processing of secondary lead into new lead", label: "Secondary lead for treatment, Re-processing of secondary lead into new lead" },
	{ value: "Copper products", label: "Copper products" },
	{ value: "Secondary copper for treatment, Re-processing of secondary copper into new copper", label: "Secondary copper for treatment, Re-processing of secondary copper into new copper" },
	{ value: "Other non-ferrous metal products", label: "Other non-ferrous metal products" },
	{ value: "Secondary other non-ferrous metals for treatment, Re-processing of secondary other non-ferrous metals into new other non-ferrous metals", label: "Secondary other non-ferrous metals for treatment, Re-processing of secondary other non-ferrous metals into new other non-ferrous metals" },
	{ value: "Foundry work services", label: "Foundry work services" },
	{ value: "Fabricated metal products, except machinery and equipment (28)", label: "Fabricated metal products, except machinery and equipment (28)" },
	{ value: "Machinery and equipment n.e.c. (29)", label: "Machinery and equipment n.e.c. (29)" },
	{ value: "Office machinery and computers (30)", label: "Office machinery and computers (30)" },
	{ value: "Electrical machinery and apparatus n.e.c. (31)", label: "Electrical machinery and apparatus n.e.c. (31)" },
	{ value: "Radio, television and communication equipment and apparatus (32)", label: "Radio, television and communication equipment and apparatus (32)" },
	{ value: "Medical, precision and optical instruments, watches and clocks (33)", label: "Medical, precision and optical instruments, watches and clocks (33)" },
	{ value: "Motor vehicles, trailers and semi-trailers (34)", label: "Motor vehicles, trailers and semi-trailers (34)" },
	{ value: "Other transport equipment (35)", label: "Other transport equipment (35)" },
	{ value: "Furniture; other manufactures goods n.e.c. (36)", label: "Furniture; other manufactures goods n.e.c. (36)" },
	{ value: "Secondary raw materials", label: "Secondary raw materials" },
	{ value: "Bottles for treatment, Recycling of bottles by direct reuse", label: "Bottles for treatment, Recycling of bottles by direct reuse" },
	{ value: "Electricity by coal", label: "Electricity by coal" },
	{ value: "Electricity by gas", label: "Electricity by gas" },
	{ value: "Electricity by nuclear", label: "Electricity by nuclear" },
	{ value: "Electricity by hydro", label: "Electricity by hydro" },
	{ value: "Electricity by wind", label: "Electricity by wind" },
	{ value: "Electricity by petroleum and other oil derivatives", label: "Electricity by petroleum and other oil derivatives" },
	{ value: "Electricity by biomass and waste", label: "Electricity by biomass and waste" },
	{ value: "Electricity by solar photovoltaic", label: "Electricity by solar photovoltaic" },
	{ value: "Electricity by solar thermal", label: "Electricity by solar thermal" },
	{ value: "Electricity by tide, wave, ocean", label: "Electricity by tide, wave, ocean" },
	{ value: "Electricity by Geothermal", label: "Electricity by Geothermal" },
	{ value: "Electricity nec", label: "Electricity nec" },
	{ value: "Transmission services of electricity", label: "Transmission services of electricity" },
	{ value: "Distribution and trade services of electricity", label: "Distribution and trade services of electricity" },
	{ value: "Coke oven gas", label: "Coke oven gas" },
	{ value: "Blast Furnace Gas", label: "Blast Furnace Gas" },
	{ value: "Oxygen Steel Furnace Gas", label: "Oxygen Steel Furnace Gas" },
	{ value: "Gas Works Gas", label: "Gas Works Gas" },
	{ value: "Biogas", label: "Biogas" },
	{ value: "Distribution services of gaseous fuels through mains", label: "Distribution services of gaseous fuels through mains" },
	{ value: "Steam and hot water supply services", label: "Steam and hot water supply services" },
	{ value: "Collected and purified water, distribution services of water (41)", label: "Collected and purified water, distribution services of water (41)" },
	{ value: "Construction work (45)", label: "Construction work (45)" },
	{ value: "Secondary construction material for treatment, Re-processing of secondary construction material into aggregates", label: "Secondary construction material for treatment, Re-processing of secondary construction material into aggregates" },
	{ value: "Sale, maintenance, repair of motor vehicles, motor vehicles parts, morotcycles, motor cycles parts and accessoirires", label: "Sale, maintenance, repair of motor vehicles, motor vehicles parts, morotcycles, motor cycles parts and accessoirires" },
	{ value: "Retail trade services of motor fuel", label: "Retail trade services of motor fuel" },
	{ value: "Wholesale trade and commission trade services, except of motor vehicles and motorcycles (51)", label: "Wholesale trade and commission trade services, except of motor vehicles and motorcycles (51)" },
	{ value: "Retail trade services, except of motor vehicles and motorcycles; repair services of personal and household goods (52)", label: "Retail trade services, except of motor vehicles and motorcycles; repair services of personal and household goods (52)" },
	{ value: "Hotel and estaurant services (55)", label: "Hotel and estaurant services (55)" },
	{ value: "Railway transportation services", label: "Railway transportation services" },
	{ value: "Other land transportation services", label: "Other land transportation services" },
	{ value: "Transportation services via pipelines", label: "Transportation services via pipelines" },
	{ value: "Sea and coastal water transportation services", label: "Sea and coastal water transportation services" },
	{ value: "Inland water transportation services", label: "Inland water transportation services" },
	{ value: "Air transport services (62)", label: "Air transport services (62)" },
	{ value: "Supporting and auxiliary transport services; travel agency services (63)", label: "Supporting and auxiliary transport services; travel agency services (63)" },
	{ value: "Post and telecommunication services (64)", label: "Post and telecommunication services (64)" },
	{ value: "Financial intermediation services, except insurance and pension funding services (65)", label: "Financial intermediation services, except insurance and pension funding services (65)" },
	{ value: "Insurance and pension funding services, except compulsory social security services (66)", label: "Insurance and pension funding services, except compulsory social security services (66)" },
	{ value: "Services auxiliary to financial intermediation (67)", label: "Services auxiliary to financial intermediation (67)" },
	{ value: "Real estate services (70)", label: "Real estate services (70)" },
	{ value: "Renting services of machinery and equipment without operator and of personal and household goods (71)", label: "Renting services of machinery and equipment without operator and of personal and household goods (71)" },
	{ value: "Computer and related services (72)", label: "Computer and related services (72)" },
	{ value: "Research and development services (73)", label: "Research and development services (73)" },
	{ value: "Other business services (74)", label: "Other business services (74)" },
	{ value: "Public administration and defence services; compulsory social security services (75)", label: "Public administration and defence services; compulsory social security services (75)" },
	{ value: "Education services (80)", label: "Education services (80)" },
	{ value: "Health and social work services (85)", label: "Health and social work services (85)" },
	{ value: "Food waste for treatment: incineration", label: "Food waste for treatment: incineration" },
	{ value: "Paper waste for treatment: incineration", label: "Paper waste for treatment: incineration" },
	{ value: "Plastic waste for treatment: incineration", label: "Plastic waste for treatment: incineration" },
	{ value: "Intert/metal waste for treatment: incineration", label: "Intert/metal waste for treatment: incineration" },
	{ value: "Textiles waste for treatment: incineration", label: "Textiles waste for treatment: incineration" },
	{ value: "Wood waste for treatment: incineration", label: "Wood waste for treatment: incineration" },
	{ value: "Oil/hazardous waste for treatment: incineration", label: "Oil/hazardous waste for treatment: incineration" },
	{ value: "Food waste for treatment: biogasification and land application", label: "Food waste for treatment: biogasification and land application" },
	{ value: "Paper waste for treatment: biogasification and land application", label: "Paper waste for treatment: biogasification and land application" },
	{ value: "Sewage sludge for treatment: biogasification and land application", label: "Sewage sludge for treatment: biogasification and land application" },
	{ value: "Food waste for treatment: composting and land application", label: "Food waste for treatment: composting and land application" },
	{ value: "Paper and wood waste for treatment: composting and land application", label: "Paper and wood waste for treatment: composting and land application" },
	{ value: "Food waste for treatment: waste water treatment", label: "Food waste for treatment: waste water treatment" },
	{ value: "Other waste for treatment: waste water treatment", label: "Other waste for treatment: waste water treatment" },
	{ value: "Food waste for treatment: landfill", label: "Food waste for treatment: landfill" },
	{ value: "Paper for treatment: landfill", label: "Paper for treatment: landfill" },
	{ value: "Plastic waste for treatment: landfill", label: "Plastic waste for treatment: landfill" },
	{ value: "Intert/metal/hazardous waste for treatment: landfill", label: "Intert/metal/hazardous waste for treatment: landfill" },
	{ value: "Textiles waste for treatment: landfill", label: "Textiles waste for treatment: landfill" },
	{ value: "Wood waste for treatment: landfill", label: "Wood waste for treatment: landfill" },
	{ value: "Membership organisation services n.e.c. (91)", label: "Membership organisation services n.e.c. (91)" },
	{ value: "Recreational, cultural and sporting services (92)", label: "Recreational, cultural and sporting services (92)" },
	{ value: "Other services (93)", label: "Other services (93)" },
	{ value: "Private households with employed persons (95)", label: "Private households with employed persons (95)" },
	{ value: "Extra-territorial organizations and bodies", label: "Extra-territorial organizations and bodies" },
]

export const SECTORS = [
	{ value: "Cultivation of paddy rice", label: "Cultivation of paddy rice", search: "Agriculture" },
	{ value: "Cultivation of wheat", label: "Cultivation of wheat", search: "Agriculture" },
	{ value: "Cultivation of cereal grains nec", label: "Cultivation of cereal grains nec", search: "Agriculture" },
	{ value: "Cultivation of vegetables, fruit, nuts", label: "Cultivation of vegetables, fruit, nuts", search: "Agriculture" },
	{ value: "Cultivation of oil seeds", label: "Cultivation of oil seeds", search: "Agriculture" },
	{ value: "Cultivation of sugar cane, sugar beet", label: "Cultivation of sugar cane, sugar beet", search: "Agriculture" },
	{ value: "Cultivation of plant-based fibers", label: "Cultivation of plant-based fibers", search: "Agriculture" },
	{ value: "Cultivation of crops nec", label: "Cultivation of crops nec", search: "Agriculture" },
	{ value: "Cattle farming", label: "Cattle farming", search: "Agriculture" },
	{ value: "Pigs farming", label: "Pigs farming", search: "Agriculture" },
	{ value: "Poultry farming", label: "Poultry farming", search: "Agriculture" },
	{ value: "Meat animals nec", label: "Meat animals nec", search: "Agriculture" },
	{ value: "Animal products nec", label: "Animal products nec", search: "Agriculture" },
	{ value: "Raw milk", label: "Raw milk", search: "Agriculture" },
	{ value: "Wool, silk-worm cocoons", label: "Wool, silk-worm cocoons", search: "Agriculture" },
	{ value: "Manure treatment (conventional), storage and land application", label: "Manure treatment (conventional), storage and land application", search: "Agriculture" },
	{ value: "Manure treatment (biogas), storage and land application", label: "Manure treatment (biogas), storage and land application", search: "Agriculture" },
	{ value: "Forestry, logging and related service activities (02)", label: "Forestry, logging and related service activities", search: "" },
	{ value: "Fishing, operating of fish hatcheries and fish farms; service activities incidental to fishing (05)", label: "Fishing, operating of fish hatcheries and fish farms; service activities incidental to fishing", search: "Aquaculture" },
	{ value: "Mining of coal and lignite; extraction of peat (10)", label: "Mining of coal and lignite; extraction of peat", search: "Mining, quarrying, energy" },
	{ value: "Extraction of crude petroleum and services related to crude oil extraction, excluding surveying", label: "Extraction of crude petroleum and services related to crude oil extraction, excluding surveying", search: "Mining, quarrying, energy" },
	{ value: "Extraction of natural gas and services related to natural gas extraction, excluding surveying", label: "Extraction of natural gas and services related to natural gas extraction, excluding surveying", search: "Mining, quarrying, energy" },
	{ value: "Extraction, liquefaction, and regasification of other petroleum and gaseous materials", label: "Extraction, liquefaction, and regasification of other petroleum and gaseous materials", search: "Mining, quarrying, energy" },
	{ value: "Mining of uranium and thorium ores (12)", label: "Mining of uranium and thorium ores", search: "Mining, quarrying, energy" },
	{ value: "Mining of iron ores", label: "Mining of iron ores", search: "Mining, quarrying" },
	{ value: "Mining of copper ores and concentrates", label: "Mining of copper ores and concentrates", search: "Mining, quarrying" },
	{ value: "Mining of nickel ores and concentrates", label: "Mining of nickel ores and concentrates", search: "Mining, quarrying" },
	{ value: "Mining of aluminium ores and concentrates", label: "Mining of aluminium ores and concentrates", search: "Mining, quarrying" },
	{ value: "Mining of precious metal ores and concentrates", label: "Mining of precious metal ores and concentrates", search: "Mining, quarrying" },
	{ value: "Mining of lead, zinc and tin ores and concentrates", label: "Mining of lead, zinc and tin ores and concentrates", search: "Mining, quarrying" },
	{ value: "Mining of other non-ferrous metal ores and concentrates", label: "Mining of other non-ferrous metal ores and concentrates", search: "Mining, quarrying" },
	{ value: "Quarrying of stone", label: "Quarrying of stone", search: "Mining, quarrying" },
	{ value: "Quarrying of sand and clay", label: "Quarrying of sand and clay", search: "Mining, quarrying" },
	{ value: "Mining of chemical and fertilizer minerals, production of salt, other mining and quarrying n.e.c.", label: "Mining of chemical and fertilizer minerals, production of salt, other mining and quarrying n.e.c.", search: "Mining, quarrying" },
	{ value: "Processing of meat cattle", label: "Processing of meat cattle", search: "Food" },
	{ value: "Processing of meat pigs", label: "Processing of meat pigs", search: "Food" },
	{ value: "Processing of meat poultry", label: "Processing of meat poultry", search: "Food" },
	{ value: "Production of meat products nec", label: "Production of meat products nec", search: "Food" },
	{ value: "Processing vegetable oils and fats", label: "Processing vegetable oils and fats", search: "Food" },
	{ value: "Processing of dairy products", label: "Processing of dairy products", search: "Food" },
	{ value: "Processed rice", label: "Processed rice", search: "Food" },
	{ value: "Sugar refining", label: "Sugar refining", search: "Food" },
	{ value: "Processing of Food products nec", label: "Processing of Food products nec", search: "Food" },
	{ value: "Manufacture of beverages", label: "Manufacture of beverages", search: "Food" },
	{ value: "Manufacture of fish products", label: "Manufacture of fish products", search: "Food" },
	{ value: "Manufacture of tobacco products (16)", label: "Manufacture of tobacco products", search: "Food" },
	{ value: "Manufacture of textiles (17)", label: "Manufacture of textiles", search: "Textiles, leather, footwear" },
	{ value: "Manufacture of wearing apparel; dressing and dyeing of fur (18)", label: "Manufacture of wearing apparel; dressing and dyeing of fur", search: "Textiles, leather, footwear" },
	{ value: "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery, harness and footwear (19)", label: "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery, harness and footwear", search: "Textiles, leather, footwear" },
	{ value: "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials (20)", label: "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials", search: "" },
	{ value: "Re-processing of secondary wood material into new wood material", label: "Re-processing of secondary wood material into new wood material", search: "" },
	{ value: "Pulp", label: "Pulp", search: "Paper" },
	{ value: "Re-processing of secondary paper into new pulp", label: "Re-processing of secondary paper into new pulp", search: "" },
	{ value: "Paper", label: "Paper", search: "Paper" },
	{ value: "Publishing, printing and reproduction of recorded media (22)", label: "Publishing, printing and reproduction of recorded media", search: "Audiovisual, broadcasting, TV, Television" },
	{ value: "Manufacture of coke oven products", label: "Manufacture of coke oven products", search: "" },
	{ value: "Petroleum Refinery", label: "Petroleum Refinery", search: "" },
	{ value: "Processing of nuclear fuel", label: "Processing of nuclear fuel", search: "" },
	{ value: "Plastics, basic", label: "Plastics, basic", search: "Chemical" },
	{ value: "Re-processing of secondary plastic into new plastic", label: "Re-processing of secondary plastic into new plastic", search: "Chemical" },
	{ value: "N-fertiliser", label: "N-fertiliser", search: "Chemical" },
	{ value: "P- and other fertiliser", label: "P- and other fertiliser", search: "Chemical" },
	{ value: "Chemicals nec", label: "Chemicals nec", search: "Chemical" },
	{ value: "Manufacture of rubber and plastic products (25)", label: "Manufacture of rubber and plastic products", search: "" },
	{ value: "Manufacture of glass and glass products", label: "Manufacture of glass and glass products", search: "Mineral" },
	{ value: "Re-processing of secondary glass into new glass", label: "Re-processing of secondary glass into new glass", search: "Mineral" },
	{ value: "Manufacture of ceramic goods", label: "Manufacture of ceramic goods", search: "Mineral" },
	{ value: "Manufacture of bricks, tiles and construction products, in baked clay", label: "Manufacture of bricks, tiles and construction products, in baked clay", search: "Mineral" },
	{ value: "Manufacture of cement, lime and plaster", label: "Manufacture of cement, lime and plaster", search: "Mineral" },
	{ value: "Re-processing of ash into clinker", label: "Re-processing of ash into clinker", search: "Mineral" },
	{ value: "Manufacture of other non-metallic mineral products n.e.c.", label: "Manufacture of other non-metallic mineral products n.e.c.", search: "Mineral" },
	{ value: "Manufacture of basic iron and steel and of ferro-alloys and first products thereof", label: "Manufacture of basic iron and steel and of ferro-alloys and first products thereof", search: "Metals" },
	{ value: "Re-processing of secondary steel into new steel", label: "Re-processing of secondary steel into new steel", search: "Metals" },
	{ value: "Precious metals production", label: "Precious metals production", search: "Metals" },
	{ value: "Re-processing of secondary preciuos metals into new preciuos metals", label: "Re-processing of secondary preciuos metals into new preciuos metals", search: "Metals" },
	{ value: "Aluminium production", label: "Aluminium production", search: "Metals" },
	{ value: "Re-processing of secondary aluminium into new aluminium", label: "Re-processing of secondary aluminium into new aluminium", search: "Metals" },
	{ value: "Lead, zinc and tin production", label: "Lead, zinc and tin production", search: "Metals" },
	{ value: "Re-processing of secondary lead into new lead, zinc and tin", label: "Re-processing of secondary lead into new lead, zinc and tin", search: "Metals" },
	{ value: "Copper production", label: "Copper production", search: "Metals" },
	{ value: "Re-processing of secondary copper into new copper", label: "Re-processing of secondary copper into new copper", search: "Metals" },
	{ value: "Other non-ferrous metal production", label: "Other non-ferrous metal production", search: "Metals" },
	{ value: "Re-processing of secondary other non-ferrous metals into new other non-ferrous metals", label: "Re-processing of secondary other non-ferrous metals into new other non-ferrous metals", search: "Metals" },
	{ value: "Casting of metals", label: "Casting of metals", search: "Metals" },
	{ value: "Manufacture of fabricated metal products, except machinery and equipment (28)", label: "Manufacture of fabricated metal products, except machinery and equipment", search: "" },
	{ value: "Manufacture of machinery and equipment n.e.c. (29)", label: "Manufacture of machinery and equipment n.e.c.", search: "" },
	{ value: "Manufacture of office machinery and computers (30)", label: "Manufacture of office machinery and computers", search: "Electronic" },
	{ value: "Manufacture of electrical machinery and apparatus n.e.c. (31)", label: "Manufacture of electrical machinery and apparatus n.e.c.", search: "Electronic" },
	{ value: "Manufacture of radio, television and communication equipment and apparatus (32)", label: "Manufacture of radio, television and communication equipment and apparatus", search: "Electronic" },
	{ value: "Manufacture of medical, precision and optical instruments, watches and clocks (33)", label: "Manufacture of medical, precision and optical instruments, watches and clocks", search: "Electronic" },
	{ value: "Manufacture of motor vehicles, trailers and semi-trailers (34)", label: "Manufacture of motor vehicles, trailers and semi-trailers", search: "" },
	{ value: "Manufacture of other transport equipment (35)", label: "Manufacture of other transport equipment", search: "" },
	{ value: "Manufacture of furniture; manufacturing n.e.c. (36)", label: "Manufacture of furniture; manufacturing n.e.c.", search: "" },
	{ value: "Recycling of waste and scrap", label: "Recycling of waste and scrap", search: "Waste management" },
	{ value: "Recycling of bottles by direct reuse", label: "Recycling of bottles by direct reuse", search: "Waste management" },
	{ value: "Production of electricity by coal", label: "Production of electricity by coal", search: "" },
	{ value: "Production of electricity by gas", label: "Production of electricity by gas", search: "" },
	{ value: "Production of electricity by nuclear", label: "Production of electricity by nuclear", search: "" },
	{ value: "Production of electricity by hydro", label: "Production of electricity by hydro", search: "" },
	{ value: "Production of electricity by wind", label: "Production of electricity by wind", search: "" },
	{ value: "Production of electricity by petroleum and other oil derivatives", label: "Production of electricity by petroleum and other oil derivatives", search: "" },
	{ value: "Production of electricity by biomass and waste", label: "Production of electricity by biomass and waste", search: "" },
	{ value: "Production of electricity by solar photovoltaic", label: "Production of electricity by solar photovoltaic", search: "" },
	{ value: "Production of electricity by solar thermal", label: "Production of electricity by solar thermal", search: "" },
	{ value: "Production of electricity by tide, wave, ocean", label: "Production of electricity by tide, wave, ocean", search: "" },
	{ value: "Production of electricity by Geothermal", label: "Production of electricity by Geothermal", search: "" },
	{ value: "Production of electricity nec", label: "Production of electricity nec", search: "" },
	{ value: "Transmission of electricity", label: "Transmission of electricity", search: "" },
	{ value: "Distribution and trade of electricity", label: "Distribution and trade of electricity", search: "" },
	{ value: "Manufacture of gas; distribution of gaseous fuels through mains", label: "Manufacture of gas; distribution of gaseous fuels through mains", search: "" },
	{ value: "Steam and hot water supply", label: "Steam and hot water supply", search: "" },
	{ value: "Collection, purification and distribution of water (41)", label: "Collection, purification and distribution of water", search: "" },
	{ value: "Construction (45)", label: "Construction", search: "" },
	{ value: "Re-processing of secondary construction material into aggregates", label: "Re-processing of secondary construction material into aggregates", search: "" },
	{ value: "Sale, maintenance, repair of motor vehicles, motor vehicles parts, motorcycles, motor cycles parts and accessoiries", label: "Sale, maintenance, repair of motor vehicles, motor vehicles parts, motorcycles, motor cycles parts and accessoiries", search: "" },
	{ value: "Retail sale of automotive fuel", label: "Retail sale of automotive fuel", search: "" },
	{ value: "Wholesale trade and commission trade, except of motor vehicles and motorcycles (51)", label: "Wholesale trade and commission trade, except of motor vehicles and motorcycles", search: "" },
	{ value: "Retail trade, except of motor vehicles and motorcycles; repair of personal and household goods (52)", label: "Retail trade, except of motor vehicles and motorcycles; repair of personal and household goods", search: "" },
	{ value: "Hotels and restaurants (55)", label: "Hotels and restaurants", search: "Accomodation, Food, Tourism" },
	{ value: "Transport via railways", label: "Transport via railways", search: "Tourism" },
	{ value: "Other land transport", label: "Other land transport", search: "Tourism" },
	{ value: "Transport via pipelines", label: "Transport via pipelines", search: "Tourism" },
	{ value: "Sea and coastal water transport", label: "Sea and coastal water transport", search: "Tourism" },
	{ value: "Inland water transport", label: "Inland water transport", search: "Tourism" },
	{ value: "Air transport (62)", label: "Air transport", search: "Tourism" },
	{ value: "Supporting and auxiliary transport activities; activities of travel agencies (63)", label: "Supporting and auxiliary transport activities; activities of travel agencies", search: "Warehousing, Tourism" },
	{ value: "Post and telecommunications (64)", label: "Post and telecommunications", search: "Courier" },
	{ value: "Financial intermediation, except insurance and pension funding (65)", label: "Financial intermediation, except insurance and pension funding", search: "" },
	{ value: "Insurance and pension funding, except compulsory social security (66)", label: "Insurance and pension funding, except compulsory social security", search: "Finance" },
	{ value: "Activities auxiliary to financial intermediation (67)", label: "Activities auxiliary to financial intermediation", search: "" },
	{ value: "Real estate activities (70)", label: "Real estate activities", search: "" },
	{ value: "Renting of machinery and equipment without operator and of personal and household goods (71)", label: "Renting of machinery and equipment without operator and of personal and household goods", search: "Administrative, support" },
	{ value: "Computer and related activities (72)", label: "Computer and related activities", search: "IT, Information" },
	{ value: "Research and development (73)", label: "Research and development", search: "Professional, Scientific, Technical" },
	{ value: "Other business activities (74)", label: "Other business activities", search: "Professional, Scientific, Technical" },
	{ value: "Public administration and defence; compulsory social security (75)", label: "Public administration and defence; compulsory social security", search: "" },
	{ value: "Education (80)", label: "Education", search: "" },
	{ value: "Health and social work (85)", label: "Health and social work", search: "" },
	{ value: "Incineration of waste: Food", label: "Incineration of waste: Food", search: "Waste management" },
	{ value: "Incineration of waste: Paper", label: "Incineration of waste: Paper", search: "Waste management" },
	{ value: "Incineration of waste: Plastic", label: "Incineration of waste: Plastic", search: "Waste management" },
	{ value: "Incineration of waste: Metals and Inert materials", label: "Incineration of waste: Metals and Inert materials", search: "Waste management" },
	{ value: "Incineration of waste: Textiles", label: "Incineration of waste: Textiles", search: "Waste management" },
	{ value: "Incineration of waste: Wood", label: "Incineration of waste: Wood", search: "Waste management" },
	{ value: "Incineration of waste: Oil/Hazardous waste", label: "Incineration of waste: Oil/Hazardous waste", search: "Waste management" },
	{ value: "Biogasification of food waste, incl. land application", label: "Biogasification of food waste, incl. land application", search: "Waste management" },
	{ value: "Biogasification of paper, incl. land application", label: "Biogasification of paper, incl. land application", search: "Waste management" },
	{ value: "Biogasification of sewage slugde, incl. land application", label: "Biogasification of sewage slugde, incl. land application", search: "Waste management" },
	{ value: "Composting of food waste, incl. land application", label: "Composting of food waste, incl. land application", search: "Waste management" },
	{ value: "Composting of paper and wood, incl. land application", label: "Composting of paper and wood, incl. land application", search: "Waste management" },
	{ value: "Waste water treatment, food", label: "Waste water treatment, food", search: "Waste management, sewerage" },
	{ value: "Waste water treatment, other", label: "Waste water treatment, other", search: "Waste management, sewerage" },
	{ value: "Landfill of waste: Food", label: "Landfill of waste: Food", search: "Waste management" },
	{ value: "Landfill of waste: Paper", label: "Landfill of waste: Paper", search: "Waste management" },
	{ value: "Landfill of waste: Plastic", label: "Landfill of waste: Plastic", search: "Waste management" },
	{ value: "Landfill of waste: Inert/metal/hazardous", label: "Landfill of waste: Inert/metal/hazardous", search: "Waste management" },
	{ value: "Landfill of waste: Textiles", label: "Landfill of waste: Textiles", search: "Waste management" },
	{ value: "Landfill of waste: Wood", label: "Landfill of waste: Wood", search: "Waste management" },
	{ value: "Activities of membership organisation n.e.c. (91)", label: "Activities of membership organisation n.e.c.", search: "" },
	{ value: "Recreational, cultural and sporting activities (92)", label: "Recreational, cultural and sporting activities", search: "Arts, Entertainment" },
	{ value: "Other service activities (93)", label: "Other service activities", search: "" },
	{ value: "Private households with employed persons (95)", label: "Private households with employed persons", search: "" },
	{ value: "Extra-territorial organizations and bodies", label: "Extra-territorial organizations and bodies", search: "" },
]

// export const TIERS = [
// 	{ value: "0", label: "0" },
// 	{ value: "1", label: "1" },
// 	{ value: "2", label: "2" },
// 	{ value: "3", label: "3" },
// 	{ value: "4", label: "4" },
// 	{ value: "5", label: "5" },
// ]

// export const YEARS = [
// 	{ value: 2011, label: "2011" },
// 	{ value: 2015, label: "2015" },
// ]