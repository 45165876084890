import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import 'leaflet/dist/leaflet.css';
import Spinner from 'react-bootstrap/Spinner';
import Switch from 'react-bootstrap/Switch';
// import { Icon } from 'leaflet';
import countries_json from "./../data/countries.json";
// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { SECTORS, COUNTRIES, IMPACTS_NEW } from "../constants"; // IMPACTS, TIERS, YEARS
import "./Menu.css";
import LoadCountriesTask from "../tasks/LoadCountriesTask";
import Map from "./Map";
import Legend from "./Legend";
import legendItems from "../entities/LegendItems";

const sectorsList = SECTORS

const countryList = COUNTRIES

const impactList = IMPACTS_NEW

// const tierslist = TIERS

// const yearsList = YEARS

const Dashboard = () => {
  const [countries, setCountries] = useState([]);

  const [regional_csv, setRegionalCsv] = useState([""]);
  // const [sectoralcsv, setSectoralCsv] = useState([""]);

  const [selectedSector, setSelectedSector] = useState(null);
  // const [selectedInterestedSector, setSelectedInterestedSector] = useState(null);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  // const [selectedImpact, setSelectedImpact] = useState(null);

  // const [origin, setOrigin] = useState("");
  // const [sector, setSector] = useState("");
  const [impact, setImpact] = useState("");
  // const [year, setYear] = useState();
  const [filtering, setFiltering] = useState();
  const [aggrResults, setAggrResults] = useState();

  const [isToggled, toggle] = useState(false);
  // const [isTiersToggled, tiersToggle] = useState(false);
  // const [tiers, setTiers] = useState(0)

  const [spinner, setSpinner] = useState(false);

  let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

//  const customIcon = new Icon({
//     iconUrl: icon,
//     shadowUrl: iconShadow
//   });

  const legendItemsInReverse = [...legendItems].reverse();

  const startAnalysis = async (r) => {
    setSpinner(true)
    await fetch('https://dst.energyatwork.it:8000/', {
    // await fetch('http://localhost:8000/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "sector": selectedSector.value,
            "originCountry": selectedOrigin.value,
            "impact": impact === null ? null : impact.value,
            // "n_tiers": tiers === null ? 0 : tiers.value,
            // "year": 2011
        })
    })
    .then(response => response.json())
    .then((response) => {
      setSpinner(false);
      // setOrigin(origin => response.originCountry)
      // setImpact(impact => response.impact)
      // setYear(year => response.year)
      // setSector(sector => response.sector)
      // setTiers(tiers => response.n_tiers)
      setFiltering(filtering => response.filtering)
      setAggrResults(aggrResults => response.aggr_result)
      setRegionalCsv(regional_csv => response.regional_csv)
      // setSectoralCsv(sectoralcsv => response.sectoral_csv)
    })
    setCountries(filtering)
  };
 
  const isInitialMount = useRef(true);

  let magnitudeText = "";
  function defineMagnitudeText(sum) {
    if(isToggled && impact != null) {
      if (impact.label == "Employment") {
        magnitudeText = `The map indicates the ${impact.value} necessary in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} thousand people total`;
      } else if (impact.label == "Employment: Female") {
        magnitudeText = `The map indicates the ${impact.value} necessary in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} thousand people total`;
      } else if (impact.label == "Employment: Male") {
        magnitudeText = `The map indicates the ${impact.value} necessary in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} thousand people total`;
      } else if (impact.label == "Employment: Vulnerable Employment") {
        magnitudeText = `The map indicates the ${impact.value} necessary in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} thousand people total`;
      } else if (impact.label == "GHG emissions (GWP100) | Problem oriented approach: baseline (CML, 2001) | GWP100 (IPCC, 2007)") {
        magnitudeText = `The map indicates the GHG emissions released by the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of GHG emissions required accross the supply chain, of ${sum}  kg CO2 equivalent total`;
      } else if (impact.label == "Land use Crop, Forest, Pasture") {
        magnitudeText = `The map indicates the ${impact.value} required in the production of the output, in each country, necessary to meet the ${regionNames.of(selectedOrigin.value)} final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} km2 total`;
      } else if (impact.label == "ozone layer depletion (ODP steady state) | Problem oriented approach: baseline (CML, 1999) | ODP steady state (WMO, 2003)") {
        magnitudeText = `The map indicates the ozone layer depletion released in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ozone layer depletion required accross the supply chain, of ${sum} kg CFC-11 equivalent total`;
      } else if (impact.label == "Raw Domestic Extraction Used") {
        magnitudeText = `The map indicates the ${impact.value} necessary in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} thousand people total`;
      } else if (impact.label == "SOx") {
        magnitudeText = `The map indicates the ${impact.value} released in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} kg total`;
      } else if (impact.label == "Total Domestic Extraction") {
        magnitudeText = `The map indicates the ${impact.value} necessary in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} thousand people total`;
      } else if (impact.label == "Water Consumption Blue - Total") {
        magnitudeText = `The map indicates the ${impact.value} in the production of the output, in each country, required to meet ${regionNames.of(selectedOrigin.value)}'s final demand for ${selectedSector.label}, in % of ${impact.value} required accross the supply chain, of ${sum} cubic Megametres total`;
      }
    } else {
      magnitudeText = `The map indicates global contributions to the demand of ${selectedSector.label} in ${regionNames.of(selectedOrigin.value)} as a fraction of ${sum} million Euro total`;
    }
    return magnitudeText;
  }

  useEffect(() => {    
    if (isInitialMount.current) {      
      isInitialMount.current = false;
    } else {
      if(filtering !== undefined) {
        const loadCountriesTask = new LoadCountriesTask(filtering, aggrResults);//, selectedInterestedSector.value);
        loadCountriesTask.load((countries) => {
          setCountries(countries)
        });
      for (var i=0; i<aggrResults.length; i++) {
        if(aggrResults[i]["ISO_A2"] == selectedOrigin.value) {
          var sum = aggrResults[i]["aggr_sum"];
        }
      } 
      document.getElementById("magnitudeText").innerText = defineMagnitudeText(sum.toFixed(2));
      }
    }
  }, [filtering])

  const impactSelected = () => {
    toggle(!isToggled)
    if(!isToggled === false) {
      setImpact("");
    }
  }

  const customSectorFilter = (option, searchText) => {
    if (option.data.search.toLowerCase().includes(searchText.toLowerCase()) || option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  // const tiersSelected = () => {
  //   tiersToggle(!isTiersToggled)
  // }
  const handleStartAnalysis = (e) => {
    try {
      e.preventDefault();      
      if(selectedSector === null && selectedOrigin === null && isToggled && (impact === "" || impact === null)) {
        document.getElementById("errorNoSector").style.display = "block";
        document.getElementById("errorNoCountry").style.display = "block";
        document.getElementById("errorNoImpact").style.display = "block";
      } else if(selectedSector === null && selectedOrigin === null && !isToggled) {
        document.getElementById("errorNoSector").style.display = "block";
        document.getElementById("errorNoCountry").style.display = "block";
        document.getElementById("errorNoImpact").style.display = "none";
      } else if(selectedSector === null) {
          document.getElementById("errorNoSector").style.display = "block";
          document.getElementById("errorNoCountry").style.display = "none";
          document.getElementById("errorNoImpact").style.display = "none";
      } else if(selectedOrigin === null) {
          document.getElementById("errorNoSector").style.display = "none"; 
          document.getElementById("errorNoCountry").style.display = "block";    
          document.getElementById("errorNoImpact").style.display = "none";   
      } else if (isToggled) {
          if(impact === "" || impact === null) {
            document.getElementById("errorNoSector").style.display = "none";
            document.getElementById("errorNoCountry").style.display = "none";
            document.getElementById("errorNoImpact").style.display = "block";
          } else if (selectedSector === null && selectedOrigin === null) {
            console.log("error");
            document.getElementById("errorNoSector").style.display = "block";
            document.getElementById("errorNoCountry").style.display = "block";
            document.getElementById("errorNoImpact").style.display = "none";
          }else if (selectedSector === null) {
            document.getElementById("errorNoSector").style.display = "block";
            document.getElementById("errorNoCountry").style.display = "none";
            document.getElementById("errorNoImpact").style.display = "none";
          } else if (selectedOrigin === null) {
            document.getElementById("errorNoSector").style.display = "none";
            document.getElementById("errorNoCountry").style.display = "block";
            document.getElementById("errorNoImpact").style.display = "none";
          } else {
            document.getElementById("errorNoSector").style.display = "none";
            document.getElementById("errorNoCountry").style.display = "none";
            document.getElementById("errorNoImpact").style.display = "none";
            startAnalysis();
          }
        } else {
          document.getElementById("errorNoSector").style.display = "none";
          document.getElementById("errorNoCountry").style.display = "none";
          document.getElementById("errorNoImpact").style.display = "none";
          startAnalysis();
        }
    } catch {}
    // setCountries(filtering)
  }

  function refreshPage() {
    window.location.reload(false)
  }

  return (
    <>
      <div className="row" style={{height:'86.2vh'}}>
        <div className="col-3" style={{backgroundColor: "#D4DADC"}}>
          {/* <br/>
          <br/> */}
          <h6 style={{marginTop:12}}>Please choose the sector</h6>
          <Select
            options={sectorsList.sort((a,b) => a.label.localeCompare(b.label))}
            defaultValue={selectedSector}
            placeholder="Select the sector"
            onChange={setSelectedSector}
            isSearchable={true}
            autosize={true}
            filterOption={customSectorFilter}
            styles={{
              control: (base, state) => ({
                ...base,
                borderColor: state.isFocused ? '#6DB97A' : '#D4DADC',
                color: "#6DB97A",
                "&:hover": {
                  borderColor: "#6DB97A",
                }
              }),
              dropdownIndicator: base => ({
                ...base,
                color: "inherit",                
              }),
              indicatorSeparator: base => ({
                ...base,
                display: 'none'
              })              
            }}
            theme={(theme) => ({
              ...theme,              
              colors: {
                ...theme.colors,
                primary25: '#6DB97A',
                primary: '#D4DADC',
              },
            })}
            // isMulti
          />
          <h6 id="errorNoSector" style={{display:"none", color:"red", fontSize:12, marginTop:5}}>Please select a sector</h6>
          <br/>
          <h6>Please choose the country</h6>
          <Select
            options={countryList.sort((a,b) => a.label.localeCompare(b.label))}
            defaultValue={selectedOrigin}
            placeholder="Select the country"
            // value={this.state.selectedOrigin}
            onChange={setSelectedOrigin}
            isSearchable={true}
            styles={{
              control: (base, state) => ({
                ...base,
                borderColor: state.isFocused ? '#6DB97A' : '#D4DADC',
                color: "#6DB97A",
                "&:hover": {
                  borderColor: "#6DB97A",
                }
              }),
              dropdownIndicator: base => ({
                ...base,
                color: "inherit",                
              }),
              indicatorSeparator: base => ({
                ...base,
                display: 'none'
              })              
            }}
            theme={(theme) => ({
              ...theme,              
              colors: {
                ...theme.colors,
                primary25: '#6DB97A',
                primary: '#D4DADC',
              },
            })}
            // isMulti
          />
          <h6 id="errorNoCountry" style={{display:"none", color:"red", fontSize:12, marginTop:5}}>Please select a country</h6>
          <br/>
          {/*<br/>   
          <h6>Please choose the sector you would like to explore</h6>
          <Select
            options={sectorsList}
            defaultValue={selectedSector}
            placeholder="Select the sector of interest"
            // value={this.state.selectedOrigin}
            onChange={setSelectedInterestedSector}
            isSearchable={true}
            autosize={true}
            // isMulti
          />
          <hr/>*/}
          {/* <br/>              
          <h6>Please choose the year</h6>
          <Select
            options={yearsList}
            defaultValue={year}
            placeholder="Select the year"
            // value={year}
            onChange={setYear}
            isSearchable={true}
          />
          <hr/> */}
          {/* <br/> */}
          <label>
            <input type="checkbox" defaultChecked={isToggled} onClick={impactSelected} style={{marginRight: 5}}/>
            <span/>
            Include the impact in the analysis
          </label>
          {isToggled && (
            <div>
              <br/>
              <h6>Please choose the impact</h6>
              <Select
                options={impactList.sort((a,b) => a.label.localeCompare(b.label))}
                defaultValue={impact}
                placeholder="Select the impact"
                // value={selectedProduct}
                onChange={setImpact}
                isSearchable={true}
                // isMulti
              />
            </div>
          )}           
          <h6 id="errorNoImpact" style={{display:"none", color:"red", fontSize:12, marginTop:5}}>Please select an impact</h6> 
          <br/>         
          {/* <label>
            <input type="checkbox" defaultChecked={isTiersToggled} onClick={tiersSelected} style={{marginRight: 5}}/>
            <span/>
            Explore tiers
          </label>
          <br/>
          {isTiersToggled && (
            <div>
              <br/>
              <h6>Please select the number of tiers you would like to explore</h6>
              <Select
                options={tierslist}
                defaultValue={tiers}
                placeholder="Select the tiers"
                // value={this.state.selectedOrigin}
                onChange={setTiers}
              />
              {/* <input 
                type="range" 
                min="0"
                max="5"
                defaultValue="0" 
                step="1"
                onChange={setTiers}/>
            </div>
          )} */}
          <br/>
          <button type="button" className="btn btn-custom" style={{fontWeight: "bold", color: "#181717", backgroundColor: "#6DB97A", position: "relative", alignItems: "normal"}} onClick={handleStartAnalysis}>Start analysis</button>
          <button type="button" className="btn btn-danger" style={{fontWeight: "bold", marginLeft: 20, position: "relative", alignItems: "normal"}} onClick={refreshPage}>Reload</button>
          <br/>
          {spinner ? <Spinner animation="border" variant="success" style={{"marginLeft":"45%", "marginTop":20}} /> : null}
          <br/>
          <p id='magnitudeText' className="col-3" style={{paddingRight: '2vh', top: '70vh', position: "absolute", fontSize:14}}></p>
          <br/>          
          {/* <p>{"The impact on \"" + impact + "\" in " + regionNames.of(origin) + " is: " + filtering}</p> */}
        </div>
        <div className="p-0 col-9">
          <Map countries={countries_json} regional_csv={regional_csv} impactSelected={isToggled} impact = {isToggled? impact.value : ""} sector = {selectedSector === null? "": selectedSector.value} selectedOrigin = {selectedOrigin === null ? "" : regionNames.of(selectedOrigin.value)}/>      
         </div>           
      </div>
      <div className="p-0 row" style={{height:'5vh'}}>
        <Legend legendItems={legendItemsInReverse}/>
        <p style={{background: "#4c4c4c", height:'2.7vh', textAlign: 'center', color:'#ffffff', fontSize:12}}><strong>Credits</strong> &nbsp; &nbsp; <a style={{color:'#ffffff'}} href='https://www.energyatwork.it/' target="_blank"><strong>Energy@Work</strong></a>&nbsp; &nbsp; <a style={{color:'#ffffff'}} href='https://www.sheffield.ac.uk/' target="_blank"><strong>University of Sheffield</strong></a></p>              
      </div>
    </>
  );
}

export default Dashboard;
