import React from "react";
// import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css'
import Navbar from "./components/Navbar";
import Dashboard from "./components/Menu";

const App = () => {
  // constructor(props) {
  //   super(props);
  // }
  // const [message, setMessage] = useState("");

  // const getWelcomeMessage = async () => {
  //   const requestOption = {
  //     method: "GET",
  //     headers: {
  //       "Content-type": "application/json"
  //     },
  //   };
  //   const response = await fetch("/home", requestOption);
  //   const data = await response.json();

  //   console.log(data);
  // }

  // useEffect(() => {
  //   getWelcomeMessage();
  // }, [])

  // render() {
    return (
      <div className="container-fluid" style={{height:'100vh'}}>
        <div className='row' style={{height:'7.5vh'}}>
          <Navbar/> 
        </div>
        <div className="row" style={{height:'92.5vh'}}>
          <div>
            <Dashboard/>
          </div>
          {/* <div className="col-9">
            <Map/>
          </div> */}
        </div>
      </div>
    );
  // }
}

export default App

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App tab="home" />);
