import React, { Component } from "react";
import image from "../asset/logo_wide_light.png"
// #6DB979

export default class Navbar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav className="navbar navbar-dark" style={{backgroundColor:"#4c4c4c", height:'7.5vh', padding:0}}>
          <div className="container-fluid">
            <a href="https://just2ce.eu/" target="_blank"><img src="https://just2ce.eu/wp-content/uploads/2021/12/just_logo-b.png" className="img-fluid" style={{height:'7.5vh', padding:0, margin:0}}></img></a>
            <div style={{textAlign:'end', paddingRight:'5vh'}}>
              <p className="navbar-brand mb-0 h1" style={{paddingBottom:5, margin:0}}>Supply Chain Explorer</p>
              {/* <span className="navbar-brand mb-0 h1" style={{fontStyle:"Italic", fontSize:10, padding:0, marginRight:5}}>powered by </span><img src={image} style={{height:'1vh'}}></img> */}
            </div>
            
          </div>
      </nav>
    );
  }
}